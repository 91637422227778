import forIn from 'lodash/forIn';
import isEmpty from 'lodash/isEmpty';
import { COST_ANALYSIS_DIMENSION_KEYS } from 'containers/CloudResourceCost-API/constants';
import { getDimensionKey } from 'components/Filter/helper';
import { isStringNull } from 'utils/helper';

const updateParams = (params, apiMigration, paramsOption) => {
  forIn(params, (value, key) => {
    if (
      [
        'selectedRange',
        'usage_chart',
        'unallocatedDetail',
        'isShowbackView',
        'saveDateRange',
        'excludeIds',
        'viewBy',
      ].includes(key) ||
      (apiMigration && ['include_allocation_rule_id', 'distribution_rule_id', 'allocation_rule_id'].includes(key))
    ) {
      return;
    }

    if (['string', 'boolean', 'number'].includes(typeof value)) {
      paramsOption.append(key, value);
    }

    if (typeof value === 'object' && value?.length) {
      let flag = false;

      const nested_keys = [
        COST_ANALYSIS_DIMENSION_KEYS.TAGS,
        COST_ANALYSIS_DIMENSION_KEYS.COST_ALLOCATION_TAGS,
        COST_ANALYSIS_DIMENSION_KEYS.LABELS,
      ];
      nested_keys.forEach(nested_key => {
        const filter_key = getDimensionKey(nested_key);

        if ([`${filter_key}_keys`, `${filter_key}_keys_and`].includes(key)) {
          flag = true;
          value.forEach(item => {
            const itemKey = isStringNull(item.value) ? '' : item.value;
            const itemValue = isStringNull(item[`${filter_key}_value`]) ? '' : item[`${filter_key}_value`];

            if ((item?.type && !item[`${filter_key}_value`]) || (item?.contain && !item[`${filter_key}_key`])) {
              if (item?.contain === 'like' || item?.contain === 'not_like') {
                paramsOption.append(`${item.contain}_${key}`, itemKey);
              } else {
                paramsOption.append(item.type !== 'Include' ? `exclude_${key}` : key, itemKey);
              }
            } else {
              const newKey = key === `${filter_key}_keys` ? filter_key : `${filter_key}_and`;

              if (item?.contain === 'like' || item?.contain === 'not_like') {
                paramsOption.append(`${item.contain}_${newKey}`, JSON.stringify({ [itemKey]: itemValue }));
              } else {
                paramsOption.append(
                  item.type !== 'Include' ? `exclude_${newKey}` : newKey,
                  JSON.stringify({ [itemKey]: itemValue }),
                );
              }
            }
          });
        }
      });

      if (key === 'showback_value') {
        value.forEach(item => {
          const itemValue = item.value === 'Unallocated' ? '' : item.value;
          paramsOption.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
        });
      } else if (!flag) {
        value.forEach(item => {
          const itemValue = item.value === 'null' ? '' : item.value;

          if (item?.contain === 'like' || item?.contain === 'not_like') {
            paramsOption.append(`${item.contain}_${key}`, itemValue);
          } else {
            paramsOption.append(item.type !== 'Include' ? `exclude_${key}` : key, itemValue);
          }
        });
      }
    }
  });

  return paramsOption;
};

export const getURLParams = (params, apiMigration) => {
  if (isEmpty(params)) {
    return {};
  }

  return updateParams(params, apiMigration, new URLSearchParams());
};

export const getComplexUrlParams = params => {
  if (!params || typeof params !== 'object' || Array.isArray(params)) {
    return new URLSearchParams();
  }

  const paramsOption = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (['string', 'boolean', 'number'].includes(typeof value)) {
      paramsOption.append(key, value);

      return;
    }

    if (Array.isArray(value)) {
      value.forEach(item => paramsOption.append(key, item));
    }
  });

  return paramsOption;
};

export const getFormData = (params, apiMigration) => {
  const formData = new FormData();

  if (isEmpty(params)) {
    return formData;
  }

  return updateParams(params, apiMigration, formData);
};
