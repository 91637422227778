import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  DAY_BEFORE_YESTERDAY,
  FILTER_DATE_FORMAT,
  LAST_30_DAYS,
  START_OF_LAST_MONTH,
  START_OF_MONTH,
  YESTERDAY,
} from 'utils/filter';
import { getMonth } from 'utils/cost-helper';
import uniq from 'lodash/uniq';
import {
  COST_ANALYSIS_DIMENSION_KEYS,
  COST_ANALYSIS_DIMENSION_NAMES,
  COST_ANALYSIS_FILTER_DIMENSION,
  BC_PLUS_TABS,
} from './constants';
import uniqBy from 'lodash/uniqBy';
import { cost_presets, isSameMonth } from 'containers/Filters-new-API/constants';
import orderBy from 'lodash/orderBy';
import { getDimensionKey, getDimensionLabel } from 'components/Filter/helper';
import { isNullOrEmpty } from 'utils/helper';

/*
 * Check if the current tab has key:value data
 */
export const isTabShowingKeyValue = activeTab => {
  return [
    COST_ANALYSIS_DIMENSION_KEYS.TAGS,
    COST_ANALYSIS_DIMENSION_KEYS.COST_ALLOCATION_TAGS,
    COST_ANALYSIS_DIMENSION_KEYS.LABELS,
  ].includes(activeTab);
};

/*
 * Get all keys of COST_ANALYSIS_FILTER_DIMENSION except the one that has key:value data
 */
export const field_keys = Object.values(COST_ANALYSIS_FILTER_DIMENSION).filter(key => !isTabShowingKeyValue(key));

/*
 * Get the dimension key of the current tab
 */
export const getCurrentTabKey = tab => {
  for (const [key, value] of Object.entries(COST_ANALYSIS_DIMENSION_KEYS)) {
    if (value === tab) {
      return key;
    } // Return immediately when found
  }

  return null; // If not found, return null
};

export const getReportParams = (data, defaultPayload) => {
  if (isEmpty(data)) {
    return defaultPayload;
  }

  const getValue = value => (isNullOrEmpty(value) ? '' : value);

  const getData = (key, type) => {
    return (data?.[key] || []).filter(item => !item?.contain && item.type === type).map(item => getValue(item.value));
  };

  const getContainData = (key, type) => {
    return (data?.[key] || []).filter(item => !item?.type && item?.contain === type).map(item => getValue(item.value));
  };

  const getNestedData = (dimension, key, type, isContain = false) => {
    const attribute_value = `${getDimensionKey(dimension)}_value`;
    const attribute_key = `${getDimensionKey(dimension)}_key`;

    return (data?.[key] || [])
      .filter(item => {
        if (!isContain) {
          return item.type === type && item?.[attribute_value] && !item?.contain;
        }

        return !item?.type && item?.[attribute_key] && item?.contain === type;
      })
      .map(item => ({
        [getValue(item.value)]: getValue(item[attribute_value]),
      }));
  };

  const getNestedKeysData = (dimension, key, type, isContain = false) => {
    const attribute_value = `${getDimensionKey(dimension)}_value`;
    const attribute_key = `${getDimensionKey(dimension)}_key`;

    return (data?.[key] || [])
      .filter(item => {
        if (!isContain) {
          return item.type === type && !item?.[attribute_value] && !item?.contain;
        }

        return !item?.type && !item?.[attribute_key] && item.contain === type;
      })
      .map(item => getValue(item.value));
  };

  let payload = { ...defaultPayload };

  field_keys.forEach(item => {
    payload = {
      ...payload,
      [item]: getData(item, 'Include'),
      [`exclude_${item}`]: getData(item, 'Exclude'),
      [`like_${item}`]: getContainData(item, 'like'),
      [`not_like_${item}`]: getContainData(item, 'not_like'),
    };
  });

  const createPayloadForKeys = (keys, dimension) => {
    return keys.reduce((acc, key) => {
      acc[key] = getNestedKeysData(dimension, key, 'Include');
      acc[`exclude_${key}`] = getNestedKeysData(dimension, key, 'Exclude');
      acc[`like_${key}`] = getNestedKeysData(dimension, key, 'like', true);
      acc[`not_like_${key}`] = getNestedKeysData(dimension, key, 'not_like', true);

      const newKey = key.replace(/_keys/, '');
      acc[newKey] = getNestedData(dimension, key, 'Include');
      acc[`exclude_${newKey}`] = getNestedData(dimension, key, 'Exclude');
      acc[`like_${newKey}`] = getNestedData(dimension, key, 'like', true);
      acc[`not_like_${newKey}`] = getNestedData(dimension, key, 'not_like', true);

      return acc;
    }, {});
  };

  // Process nested keys for tags, labels, and cost allocation tags
  const tagKey = `${getDimensionKey(COST_ANALYSIS_DIMENSION_KEYS.TAGS)}_keys`;
  const tagPayload = createPayloadForKeys([tagKey, `${tagKey}_and`], COST_ANALYSIS_DIMENSION_KEYS.TAGS);
  const labelKey = `${getDimensionKey(COST_ANALYSIS_DIMENSION_KEYS.LABELS)}_keys`;
  const labelPayload = createPayloadForKeys([labelKey, `${labelKey}_and`], COST_ANALYSIS_DIMENSION_KEYS.LABELS);
  const costAllocationKey = `${getDimensionKey(COST_ANALYSIS_DIMENSION_KEYS.COST_ALLOCATION_TAGS)}_keys`;

  const costAllocationPayload = createPayloadForKeys(
    [costAllocationKey, `${costAllocationKey}_and`],
    COST_ANALYSIS_DIMENSION_KEYS.COST_ALLOCATION_TAGS,
  );

  return {
    ...payload,
    ...tagPayload,
    ...labelPayload,
    ...costAllocationPayload,
  };
};

export const setFilterParams = (data, params) => {
  if (isEmpty(data)) {
    return params;
  }

  const getPayloadData = (dimension, paramsData = [], item, type = {}) => {
    return [...paramsData].map(detail => ({
      value: detail,
      label: isNullOrEmpty(detail) ? getDimensionLabel(dimension) : detail,
      ...type,
    }));
  };

  const getNestedPayloadData = (dimension, key, paramsData = [], type = {}) => {
    const label = getDimensionLabel(dimension);

    return [...paramsData].map(item => {
      const value = Object.keys(item)?.[0] || '';
      const tag_value = Object.values(item)?.[0] || '';

      return {
        value: isNullOrEmpty(value) ? label : value,
        [key]: isNullOrEmpty(value) ? label : value,
        [`${getDimensionKey(dimension)}_value`]: isNullOrEmpty(tag_value) ? label : tag_value,
        ...type,
      };
    });
  };

  let payload = { ...params };

  if (data?.custom_filters) {
    const jsonData = JSON.parse(data.custom_filters);

    if (jsonData?.saveDateRange) {
      const rec = cost_presets.find(item => item.text === jsonData?.selectedRange);
      payload = {
        ...payload,
        ...JSON.parse(data.custom_filters || '{}'),
        date_start: rec?.start.format(FILTER_DATE_FORMAT) || jsonData.date_start,
        date_end: rec?.end.format(FILTER_DATE_FORMAT) || jsonData.date_end,
      };
    } else {
      payload = {
        ...payload,
        ...JSON.parse(data.custom_filters || '{}'),
      };
    }
  }

  const reversedObj = {};

  for (const key in COST_ANALYSIS_FILTER_DIMENSION) {
    reversedObj[COST_ANALYSIS_FILTER_DIMENSION[key]] = key;
  }

  field_keys.forEach(item => {
    payload = {
      ...payload,
      [item]: [
        ...getPayloadData(reversedObj[item], data?.[item], item, {
          type: 'Include',
        }),
        ...getPayloadData(reversedObj[item], data?.[`exclude_${item}`], item, {
          type: 'Exclude',
        }),
        ...getPayloadData(reversedObj[item], data?.[`like_${item}`], item, {
          contain: 'like',
        }),
        ...getPayloadData(reversedObj[item], data?.[`not_like_${item}`], item, {
          contain: 'not_like',
        }),
      ],
    };
  });

  const createPayloadSection = (dimension, keys, dataKey, nestedKey) => {
    return [
      ...getPayloadData(dimension, data?.[keys], keys, {
        [`${dataKey}_value`]: '',
        type: 'Include',
      }),
      ...getPayloadData(dimension, data?.[`exclude_${keys}`], keys, {
        [`${dataKey}_value`]: '',
        type: 'Exclude',
      }),
      ...getPayloadData(dimension, data?.[`like_${keys}`], keys, {
        [`${dataKey}_key`]: '',
        contain: 'like',
      }),
      ...getPayloadData(dimension, data?.[`not_like_${keys}`], keys, {
        [`${dataKey}_key`]: '',
        contain: 'not_like',
      }),
      ...getNestedPayloadData(dimension, 'label', data?.[dataKey] || [], { type: 'Include' }),
      ...getNestedPayloadData(dimension, 'label', data?.[`exclude_${dataKey}`] || [], {
        type: 'Exclude',
      }),
      ...getNestedPayloadData(dimension, nestedKey, data?.[`like_${dataKey}`] || [], {
        contain: 'like',
      }),
      ...getNestedPayloadData(dimension, nestedKey, data?.[`not_like_${dataKey}`] || [], {
        contain: 'not_like',
      }),
    ];
  };

  const createPayload = (dimension, key, dataKey, nestedKey) => {
    return {
      [key]: createPayloadSection(dimension, key, dataKey, nestedKey),
      [`${key}_and`]: createPayloadSection(dimension, `${key}_and`, `${dataKey}_and`, nestedKey),
    };
  };

  const tagKey = getDimensionKey(COST_ANALYSIS_DIMENSION_KEYS.TAGS);
  const labelKey = getDimensionKey(COST_ANALYSIS_DIMENSION_KEYS.LABELS);
  const costAllocationTagKey = getDimensionKey(COST_ANALYSIS_DIMENSION_KEYS.COST_ALLOCATION_TAGS);
  const tagPayload = createPayload(COST_ANALYSIS_DIMENSION_KEYS.TAGS, `${tagKey}_keys`, tagKey, `${tagKey}_key`);

  const labelPayload = createPayload(
    COST_ANALYSIS_DIMENSION_KEYS.LABELS,
    `${labelKey}_keys`,
    labelKey,
    `${labelKey}_key`,
  );

  const costAllocationPayload = createPayload(
    COST_ANALYSIS_DIMENSION_KEYS.COST_ALLOCATION_TAGS,
    `${costAllocationTagKey}_keys`,
    costAllocationTagKey,
    `${costAllocationTagKey}_key`,
  );

  payload = {
    ...payload,
    ...tagPayload,
    ...labelPayload,
    ...costAllocationPayload,
  };

  return payload;
};

export const initialColumns = [
  { key: 'name', label: 'Name', order: '' },
  { key: 'total', label: 'Total', order: '' },
  {
    key: 'spend',
    label: 'Total Spend(%)',
    small: '',
    order: '',
  },
  {
    key: 'previous_interval_spend',
    label: "Last period's",
    small: 'spend',
    order: '',
  },
];

export function getDaysBetweenRange(startDate = null, endDate = null) {
  if (isValidDates(startDate, endDate)) {
    return [];
  }

  const dateArray = [];
  const startDateClone = moment(startDate, FILTER_DATE_FORMAT);
  const endDateClone = moment(endDate, FILTER_DATE_FORMAT);

  while (startDateClone.format(FILTER_DATE_FORMAT) <= endDateClone.format(FILTER_DATE_FORMAT)) {
    dateArray.push({
      key: startDateClone.format(FILTER_DATE_FORMAT) + 'T00:00:00',
      label: startDateClone.format('DD MMM YYYY'),
      date_start: startDateClone.format(FILTER_DATE_FORMAT),
      date_end: startDateClone.format(FILTER_DATE_FORMAT),
      order: '',
    });
    startDateClone.add(1, 'days');
  }

  return dateArray;
}

export function getWeeksBetweenRange(startDate = null, endDate = null) {
  if (isValidDates(startDate, endDate)) {
    return [];
  }

  const dateArray = [];
  let startDateClone = moment(startDate, FILTER_DATE_FORMAT).isoWeekday(1);
  let endDateClone = moment(endDate, FILTER_DATE_FORMAT).isoWeekday(7);

  if (endDateClone.format(FILTER_DATE_FORMAT) > YESTERDAY.format(FILTER_DATE_FORMAT)) {
    endDateClone = moment().subtract(1, 'week').isoWeekday(7);
  }

  while (startDateClone.format(FILTER_DATE_FORMAT) <= endDateClone.format(FILTER_DATE_FORMAT)) {
    let newDate = moment(startDateClone);
    newDate = newDate.add(1, 'weeks');

    if (newDate.format(FILTER_DATE_FORMAT) >= endDateClone) {
      dateArray.push({
        date_start: startDateClone.format(FILTER_DATE_FORMAT),
        date_end: endDateClone.format(FILTER_DATE_FORMAT),
        key: startDateClone.format(FILTER_DATE_FORMAT) + 'T00:00:00',
        label: `${startDateClone.format('DD')}-${endDateClone.format('DD MMM YYYY')}`,
        order: '',
      });
    } else {
      const ed = moment(startDateClone).add(6, 'days');
      dateArray.push({
        date_start: startDateClone.format(FILTER_DATE_FORMAT),
        date_end: ed.format(FILTER_DATE_FORMAT),
        key: startDateClone.format(FILTER_DATE_FORMAT) + 'T00:00:00',
        label: `${startDateClone.format('DD')}-${ed.format('DD MMM YYYY')}`,
        order: '',
      });
    }

    startDateClone = newDate;
  }

  return dateArray;
}

export function getMonthsBetweenRange(startDate = null, endDate = null) {
  if (isValidDates(startDate, endDate)) {
    return [];
  }

  const dateArray = [];
  const startDateClone = moment(startDate, FILTER_DATE_FORMAT).set('date', 1);
  let endDateClone = endDate;

  if (!moment(endDate).isSame(new Date(), 'month')) {
    endDateClone = moment(endDate).endOf('month').format(FILTER_DATE_FORMAT);
  }

  while (startDateClone.format(FILTER_DATE_FORMAT) <= endDateClone) {
    dateArray.push({
      key: startDateClone.format(FILTER_DATE_FORMAT) + 'T00:00:00',
      label: `${startDateClone.format('MMM-YYYY')}`,
      date_start: startDateClone.format(FILTER_DATE_FORMAT),
      date_end: !moment(moment(startDateClone).endOf('month')).isSame(new Date(), 'month')
        ? moment(startDateClone).endOf('month').format(FILTER_DATE_FORMAT)
        : YESTERDAY.format(FILTER_DATE_FORMAT),
      order: '',
    });
    startDateClone.add(1, 'month');
  }

  return dateArray;
}

export function getHoursBetweenRange(startDate = null, endDate = null) {
  if (isValidDates(startDate, endDate)) {
    return [];
  }

  const dateArray = [];
  const startDateClone = moment(startDate, FILTER_DATE_FORMAT);
  const endDateClone = moment(endDate, FILTER_DATE_FORMAT);

  while (startDateClone.format(FILTER_DATE_FORMAT) <= endDateClone.format(FILTER_DATE_FORMAT)) {
    for (let i = 0; i < 24; i++) {
      dateArray.push({
        key: startDateClone.format(FILTER_DATE_FORMAT) + `T${i < 10 ? `0${i}` : i}:00:00`,
        label: `${i < 10 ? `0${i}` : i}:00 ` + startDateClone.format('MM/DD/YYYY'),
        date_start: startDateClone.format(FILTER_DATE_FORMAT) + `T${i < 10 ? `0${i}` : i}:00:00`,
        date_end: startDateClone.format(FILTER_DATE_FORMAT) + `T${i < 10 ? `0${i}` : i}:00:00`,
        order: '',
      });
    }

    startDateClone.add(1, 'days');
  }

  return dateArray;
}

const getMonthDate = (quarter, year) => {
  let eDate = moment();

  switch (quarter) {
    case 1: {
      eDate = moment(moment().set({ date: 1, month: 0, year }))
        .add(2, 'month')
        .endOf('month');

      break;
    }

    case 2: {
      eDate = moment(moment().set({ date: 1, month: 3, year }))
        .add(2, 'month')
        .endOf('month');

      break;
    }

    case 3: {
      eDate = moment(moment().set({ date: 1, month: 6, year }))
        .add(2, 'month')
        .endOf('month');

      break;
    }

    case 4: {
      eDate = moment(moment().set({ date: 1, month: 9, year }))
        .add(2, 'month')
        .endOf('month');

      break;
    }

    default:
      break;
  }

  return moment(eDate).isSame(new Date(), 'month') && moment(eDate).isSame(new Date(), 'year') ? YESTERDAY : eDate;
};

export function getQuarterBetweenRange(startDate = null, endDate = null) {
  if (isValidDates(startDate, endDate)) {
    return [];
  }

  const dateArray = [];
  const startDateClone = moment(startDate, FILTER_DATE_FORMAT).set('date', 1);
  let endDateClone = endDate;

  if (!moment(endDate).isSame(new Date(), 'month')) {
    endDateClone = moment(endDate).endOf('month').format(FILTER_DATE_FORMAT);
  }

  let quarter = startDateClone.quarter();
  let firstDate = getMonth(quarter, startDateClone.year());
  let secondDate = getMonthDate(quarter, startDateClone.year());
  let year = firstDate.year();

  while (firstDate.format(FILTER_DATE_FORMAT) <= endDateClone) {
    dateArray.push({
      key: firstDate.format(FILTER_DATE_FORMAT) + 'T00:00:00',
      date_start: firstDate.format(FILTER_DATE_FORMAT),
      date_end: secondDate.format(FILTER_DATE_FORMAT),
      label: `Quarter ${quarter} - ${year}`,
      order: '',
    });
    year = quarter === 4 ? year + 1 : year;
    quarter = quarter === 4 ? 1 : quarter + 1;
    firstDate = getMonth(quarter, year);
    secondDate = getMonthDate(quarter, year);
  }

  return dateArray;
}

const isValidDates = (startDate, endDate) =>
  !moment(startDate, FILTER_DATE_FORMAT).isValid() || !moment(endDate, FILTER_DATE_FORMAT).isValid();

export function getYearBetweenRange(startDate = null, endDate = null) {
  if (isValidDates(startDate, endDate)) {
    return [];
  }

  const dateArray = [];
  const startDateClone = moment(startDate, FILTER_DATE_FORMAT).set('date', 1);
  let endDateClone = endDate;

  if (!moment(endDate).isSame(new Date(), 'year')) {
    endDateClone = moment(endDate).endOf('year').format(FILTER_DATE_FORMAT);
  }

  while (startDateClone.format(FILTER_DATE_FORMAT) <= endDateClone) {
    dateArray.push({
      key: startDateClone.format(FILTER_DATE_FORMAT) + 'T00:00:00',
      label: `${startDateClone.format('YYYY')}`,
      date_start: startDateClone.format(FILTER_DATE_FORMAT),
      date_end: !moment(moment(startDateClone).endOf('year')).isSame(new Date(), 'year')
        ? moment(startDateClone).endOf('year').format(FILTER_DATE_FORMAT)
        : YESTERDAY.format(FILTER_DATE_FORMAT),
      order: '',
    });
    startDateClone.add(1, 'year');
  }

  return dateArray;
}

export function getDateRange(type, { date_start, date_end }) {
  switch (type) {
    case 'week': {
      const startDateClone = moment(date_start, FILTER_DATE_FORMAT).isoWeekday(1).format(FILTER_DATE_FORMAT);
      let endDateClone = moment(date_end, FILTER_DATE_FORMAT).isoWeekday(7).format(FILTER_DATE_FORMAT);

      if (endDateClone > YESTERDAY.format(FILTER_DATE_FORMAT)) {
        endDateClone = YESTERDAY.format(FILTER_DATE_FORMAT);
      }

      return {
        date_start: startDateClone,
        date_end: endDateClone,
      };
    }

    case 'month': {
      const startDateClone = moment(date_start, FILTER_DATE_FORMAT).set('date', 1);
      let endDateClone = date_end;

      if (!moment(date_end).isSame(new Date(), 'month')) {
        endDateClone = moment(date_end).endOf('month').format(FILTER_DATE_FORMAT);
      }

      return {
        date_start: startDateClone.format(FILTER_DATE_FORMAT),
        date_end: endDateClone,
      };
    }

    case 'quarter': {
      const startDateClone = moment(date_start, FILTER_DATE_FORMAT).set('date', 1);
      const quarter = startDateClone.quarter();
      const firstDate = getMonth(quarter, startDateClone.year());
      let endDateClone = date_end;

      if (!moment(date_end).isSame(new Date(), 'quarter')) {
        endDateClone = moment(date_end).endOf('quarter').format(FILTER_DATE_FORMAT);
      }

      return {
        date_start: moment(firstDate).format(FILTER_DATE_FORMAT),
        date_end: endDateClone,
      };
    }

    default:
      return { date_start, date_end };
  }
}

export const getParams = (activeShowback = {}, value = '', allocationRecord = undefined) => {
  const rec = (activeShowback?.combined_values || []).find(item => item.name === value);

  if (allocationRecord?.id) {
    return {
      showback_value: undefined,
    };
  }

  return {
    showback_value: !rec
      ? [{ value, type: 'Include' }]
      : rec.combined_values.map(item => ({
          value: item,
          type: 'Include',
        })),
  };
};

export const getParamByDimension = (item, activeTab, params, flag, detailItem) => {
  if (flag) {
    const label = getDimensionKey(activeTab);

    return {
      ...params,
      [`${label}_keys`]: [
        ...(params?.[`${label}_keys`] || []),
        {
          value: detailItem.name,
          type: 'Include',
          label: detailItem.name,
          [`${label}_value`]: item.name,
        },
      ],
    };
  }

  const key = COST_ANALYSIS_FILTER_DIMENSION[activeTab] || '';

  if (!key) {
    return params;
  }

  return {
    ...params,
    [key]: uniqBy(
      [
        ...(params?.[key] || []),
        {
          value: item.key,
          type: 'Include',
          label: item.name,
        },
      ],
      'value',
    ),
  };
};

export const getIsFullMonth = payload => {
  const start_date = moment(payload.date_start, FILTER_DATE_FORMAT).date();
  const end_date = moment(payload.date_end, FILTER_DATE_FORMAT).date();
  const start_month = moment(payload.date_start, FILTER_DATE_FORMAT).month();
  const end_month = moment(payload.date_end, FILTER_DATE_FORMAT).month();
  const start_year = moment(payload.date_start, FILTER_DATE_FORMAT).year();
  const end_year = moment(payload.date_end, FILTER_DATE_FORMAT).year();

  const startDay = moment({
    year: moment(payload.date_start, FILTER_DATE_FORMAT).year(),
    month: moment(payload.date_start, FILTER_DATE_FORMAT).month(),
    day: 1,
  });
  const end = startDay.endOf('month').date();
  const month = start_month === end_month && start_year === end_year && start_date === 1 && end_date === end;
  const quarterRange = getQuarterBetweenRange(payload.date_start, payload.date_end);
  let quarter = false,
    new_end_date = payload.date_end;

  if (!month) {
    quarterRange.forEach(item => {
      if (
        payload.granularity === 'quarter' ||
        (item.date_start === payload.date_start && item.date_end === payload.date_end) ||
        (payload.granularity === 'quarter' && YESTERDAY.format(FILTER_DATE_FORMAT) === payload.date_end)
      ) {
        quarter = true;
        new_end_date = moment(quarterRange[quarterRange.length - 1].date_end, FILTER_DATE_FORMAT)
          .subtract(quarterRange.length * 3, 'month')
          .endOf('month')
          .format(FILTER_DATE_FORMAT);
      }
    });
  }

  const dates = {
    date_start: month
      ? moment(payload.date_start, FILTER_DATE_FORMAT).subtract(1, 'month').startOf('month').format(FILTER_DATE_FORMAT)
      : moment(payload.date_start, FILTER_DATE_FORMAT)
          .subtract(quarterRange.length * 3, 'month')
          .startOf('month')
          .format(FILTER_DATE_FORMAT),
    date_end: month
      ? moment(payload.date_start, FILTER_DATE_FORMAT).subtract(1, 'month').endOf('month').format(FILTER_DATE_FORMAT)
      : new_end_date,
  };

  const diff =
    moment(payload.date_end, FILTER_DATE_FORMAT).diff(moment(payload.date_start, FILTER_DATE_FORMAT), 'days') + 1;

  const last_date_start = moment(payload.date_start, FILTER_DATE_FORMAT)
    .subtract(diff, 'days')
    .format(FILTER_DATE_FORMAT);
  const last_date_end = moment(payload.date_end, FILTER_DATE_FORMAT).subtract(diff, 'days').format(FILTER_DATE_FORMAT);

  return {
    flag: month || quarter,
    last_date_start,
    last_date_end,
    ...dates,
  };
};

const default_date_range = {
  api_date_start: LAST_30_DAYS.clone().subtract(31, 'days').format(FILTER_DATE_FORMAT),
  api_date_end: YESTERDAY.format(FILTER_DATE_FORMAT),
  date_start: LAST_30_DAYS.format(FILTER_DATE_FORMAT),
  date_end: YESTERDAY.format(FILTER_DATE_FORMAT),
  previous_date_start: LAST_30_DAYS.clone().subtract(31, 'days').format(FILTER_DATE_FORMAT),
  previous_date_end: YESTERDAY.clone().subtract(31, 'days').format(FILTER_DATE_FORMAT),
  mtd_date_start: START_OF_MONTH.format(FILTER_DATE_FORMAT),
  mtd_date_end: YESTERDAY.format(FILTER_DATE_FORMAT),
  previous_mtd_date_start: START_OF_MONTH.clone().subtract(1, 'month').format(FILTER_DATE_FORMAT),
  previous_mtd_date_end: YESTERDAY.clone().subtract(1, 'month').format(FILTER_DATE_FORMAT),
  yesterday_date: YESTERDAY.format(FILTER_DATE_FORMAT),
  previous_yesterday_date: DAY_BEFORE_YESTERDAY.format(FILTER_DATE_FORMAT),
};

export const getDateRanges = (start_date = '', end_date = '', granularity) => {
  if (isValidDates(start_date, end_date)) {
    return default_date_range;
  }

  // Date start and Date end
  const startMoment = moment(start_date, FILTER_DATE_FORMAT);
  const endMoment = moment(end_date, FILTER_DATE_FORMAT);
  // Difference between Date start and Date end
  const daysDiff = endMoment.diff(startMoment, 'days') + 1;
  // Dates for Previous Period
  let previous_date_end, previous_date_start;
  // Previous Month's MTD date
  const previous_mtd_date_start = START_OF_MONTH.clone().subtract(1, 'month');
  // Function to check if dates are for Full Month
  const isFullMonth = () => startMoment.date() === 1 && endMoment.date() === endMoment.daysInMonth();

  switch (granularity) {
    case 'week': {
      const weeksCount = Math.ceil(endMoment.diff(startMoment, 'days') / 7);
      previous_date_start = startMoment.clone().subtract(weeksCount, 'weeks').isoWeekday(1);
      previous_date_end = endMoment.clone().subtract(weeksCount, 'weeks').isoWeekday(7);

      break;
    }

    case 'month': {
      const monthsCount = Math.ceil(endMoment.diff(startMoment, 'months') + 1);
      previous_date_start = startMoment.clone().subtract(monthsCount, 'months').startOf('month');
      previous_date_end = endMoment.clone().subtract(monthsCount, 'months').endOf('month');

      break;
    }

    case 'quarter': {
      const quartersCount = Math.ceil(endMoment.diff(startMoment, 'quarters') + 1);
      previous_date_start = startMoment.clone().subtract(quartersCount, 'quarters').startOf('quarter');
      previous_date_end = endMoment.clone().subtract(quartersCount, 'quarters').endOf('quarter');

      break;
    }

    default: {
      previous_date_end = startMoment.clone().subtract(1, 'days');
      previous_date_start = previous_date_end.clone().subtract(daysDiff - 1, 'days');

      break;
    }
  }

  // Date start and Date end for API
  let api_date_start = startMoment;
  let api_date_end = endMoment;

  // Case 1: Date range < 30 days, and end_date is yesterday
  if (daysDiff < 31 && isEndDateYesterday(end_date)) {
    api_date_start = moment.min(START_OF_MONTH, startMoment);
    api_date_end = YESTERDAY;
  }
  // Case 1: Handle full-month range
  else if (isFullMonth()) {
    const monthsDiff = endMoment.diff(startMoment, 'months') + 1;
    previous_date_end = startMoment.clone().subtract(1, 'months').endOf('month');
    previous_date_start = previous_date_end
      .clone()
      .subtract(monthsDiff - 1, 'months')
      .startOf('month');
  }

  return {
    ...default_date_range,
    api_date_start: api_date_start.format(FILTER_DATE_FORMAT),
    api_date_end: api_date_end.format(FILTER_DATE_FORMAT),
    date_start: startMoment.format(FILTER_DATE_FORMAT),
    date_end: endMoment.format(FILTER_DATE_FORMAT),
    previous_date_start: previous_date_start.format(FILTER_DATE_FORMAT),
    previous_date_end: previous_date_end.format(FILTER_DATE_FORMAT),
    previous_mtd_date_start: previous_mtd_date_start.format(FILTER_DATE_FORMAT),
  };
};

export const getMTDDates = params => {
  const flag =
    moment(params.date_end, FILTER_DATE_FORMAT).format(FILTER_DATE_FORMAT) === YESTERDAY.format(FILTER_DATE_FORMAT) &&
    ['day', 'hour', 'month', 'week'].includes(params.granularity);

  const dates = {
    date_start: START_OF_LAST_MONTH.format(FILTER_DATE_FORMAT),
    date_end: moment().utc().isSame(moment().utc().subtract(1, 'days'), 'month')
      ? YESTERDAY.clone().subtract(1, 'month').format(FILTER_DATE_FORMAT)
      : START_OF_LAST_MONTH.format(FILTER_DATE_FORMAT),
  };

  return {
    flag,
    afterStartDate: moment(params.date_start, FILTER_DATE_FORMAT).isAfter(START_OF_MONTH),
    current_date_start: START_OF_MONTH.format(FILTER_DATE_FORMAT),
    current_date_end: isSameMonth(moment().utc(), moment().utc().subtract(1, 'days'))
      ? moment().utc().subtract(1, 'days').format(FILTER_DATE_FORMAT)
      : moment().utc().set('date', 1).format(FILTER_DATE_FORMAT),
    ...dates,
  };
};

export const getLastDayDates = params => {
  const flag =
    moment(params.date_end, FILTER_DATE_FORMAT).format(FILTER_DATE_FORMAT) === YESTERDAY.format(FILTER_DATE_FORMAT) &&
    ['day', 'hour', 'month', 'week'].includes(params.granularity);

  return {
    flag,
    date_start: DAY_BEFORE_YESTERDAY.format(FILTER_DATE_FORMAT),
    date_end: DAY_BEFORE_YESTERDAY.format(FILTER_DATE_FORMAT),
    current_date_start: YESTERDAY.format(FILTER_DATE_FORMAT),
    current_date_end: YESTERDAY.format(FILTER_DATE_FORMAT),
  };
};

export const getShowbackDistributionCost = (
  response = {},
  showback = {},
  prevResponse = {},
  totalResponse = undefined,
) => {
  let cost_distribution = {};
  Object.keys(response?.data || {}).forEach(item => {
    if (
      totalResponse &&
      !totalResponse?.allowed_all_showback_values &&
      !(totalResponse?.showback_values || []).includes(item || 'Unallocated')
    ) {
      return;
    }

    cost_distribution = {
      ...cost_distribution,
      [item || 'Unallocated']: {
        ...(response?.data?.[item] || {}),
        prev_total: prevResponse?.data?.[item]?.total || 0,
      },
    };
  });

  let nameList = [];

  if (showback?.combined_values?.length) {
    showback.combined_values.forEach(combined => {
      if (cost_distribution[combined.name]) {
        const combinedData = combined.combined_values.slice(1);
        cost_distribution[combined.name] = {
          ...cost_distribution[combined.name],
          combined: {
            ...combined,
            combined_values: combinedData,
          },
        };
        combinedData.forEach(item => delete cost_distribution[item]);
        nameList = [...nameList, ...combinedData];
      }
    });
  }

  const allocated = Object.entries(cost_distribution)
    .filter(([key]) => key !== 'Unallocated')
    .reduce((acc, [, value]) => acc + value.total, 0);
  const unallocated = cost_distribution?.['Unallocated']?.total || 0;

  const formatted_cost_distribution = [
    ...orderBy(
      Object.entries(cost_distribution)
        .filter(([tag_key]) => tag_key !== 'Unallocated')
        .map(([tag_key, { total: cost, combined }]) => ({
          tag_key: showback?.name || '',
          tag_value: tag_key,
          cost,
          combined,
        })),
      ['cost'],
      'desc',
    ),
  ];

  if (cost_distribution?.['Unallocated']) {
    formatted_cost_distribution.push({
      tag_key: showback?.name || '',
      tag_value: 'Unallocated',
      cost: cost_distribution?.['Unallocated']?.total,
      combined: {},
    });
  }

  nameList = uniq([...nameList, ...Object.keys(cost_distribution)]);

  return {
    cost_distribution,
    formatted_cost_distribution,
    allocated,
    unallocated,
    nameList,
    total: allocated + unallocated,
    tag_keys: showback.tag_keys,
    allocation_tag_type: showback.allocation_tag_type,
  };
};

// Filter data within a specific date range
const filterByDateRange = (data, startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);

  return Object.fromEntries(
    Object.entries(data).map(([key, { granular = {} }]) => {
      // Use reduce to filter granular data and calculate the total in one pass
      const { filteredGranular, filteredTotal } = Object.entries(granular).reduce(
        (acc, [date, value]) => {
          const currentDate = moment(date);

          if (currentDate.isBetween(start, end, 'days', '[]')) {
            acc.filteredGranular[date] = value;
            acc.filteredTotal += value;
          }

          return acc;
        },
        { filteredGranular: {}, filteredTotal: 0 }, // Initial accumulator
      );

      return [key, { granular: filteredGranular, total: filteredTotal }];
    }),
  );
};

const getApiResponse = (key, apiCallMapping, apiResponse) => {
  const index = apiCallMapping[key];

  if (index === undefined) {
    return undefined;
  } // Key not present in the mapping

  return apiResponse[index]?.status === 200 ? apiResponse[index] : {}; // Check status
};

const splitResultBasedOnRange = (apiResponse, range, apiCallMapping) => {
  const showback = getApiResponse('showback', apiCallMapping, apiResponse);
  const showback_prev = getApiResponse('showback_prev', apiCallMapping, apiResponse);
  const showbackUsage = getApiResponse('showbackUsage', apiCallMapping, apiResponse);
  const showbackUsage_prev = getApiResponse('showbackUsage_prev', apiCallMapping, apiResponse);
  const byDaysShowback = getApiResponse('byDaysShowback', apiCallMapping, apiResponse); // When granularity is not day
  const byDaysShowback_prev = getApiResponse('byDaysShowback_prev', apiCallMapping, apiResponse); // When granularity is not day

  const {
    date_start,
    date_end,
    previous_date_start,
    previous_date_end,
    mtd_date_start,
    mtd_date_end,
    previous_mtd_date_start,
    previous_mtd_date_end,
    yesterday_date,
    previous_yesterday_date,
  } = range;

  // Helper: Calculate total spend for each key
  const calculateTotalSpend = filteredData =>
    Object.keys(filteredData).reduce((totals, key) => {
      totals[key] = Object.values(filteredData[key].granular || {}).reduce((sum, value) => sum + value, 0);

      return totals;
    }, {});

  // Helper: Build a filtered response
  const buildFilteredResponse = (source, startDate, endDate) => ({
    ...source,
    data: filterByDateRange(source?.data || {}, startDate, endDate),
  });
  // Build responses for different ranges
  const response = buildFilteredResponse(showback, date_start, date_end);
  const prevResponse = buildFilteredResponse(showback_prev, previous_date_start, previous_date_end);
  const usageResponse = buildFilteredResponse(showbackUsage, date_start, date_end);
  const usagePrevResponse = buildFilteredResponse(showbackUsage_prev, previous_date_start, previous_date_end);
  // Calculate totals for ranges
  const intervalSpend = calculateTotalSpend(response?.data || {});
  const previousIntervalSpend = calculateTotalSpend(prevResponse?.data || {});
  let mtdSpend = {};
  let mtdDeltaSpend = {};
  let yesterdaySpend = {};
  let yesterdayDeltaSpend = {};

  if (isEndDateYesterday(date_end)) {
    const showbackData = byDaysShowback?.data || showback?.data;
    const previousShowbackData = byDaysShowback_prev?.data || showback_prev?.data;
    yesterdaySpend = calculateTotalSpend(filterByDateRange(showbackData, yesterday_date, yesterday_date));

    const prevYesterdayData = isEndDateYesterday(date_start) ? previousShowbackData : showbackData;
    mtdSpend = calculateTotalSpend(filterByDateRange(showbackData, mtd_date_start, mtd_date_end));
    mtdDeltaSpend = calculateTotalSpend(
      filterByDateRange(previousShowbackData, previous_mtd_date_start, previous_mtd_date_end),
    );
    yesterdaySpend = calculateTotalSpend(filterByDateRange(showbackData, yesterday_date, yesterday_date));
    yesterdayDeltaSpend = calculateTotalSpend(
      filterByDateRange(prevYesterdayData, previous_yesterday_date, previous_yesterday_date),
    );
  }

  // Return structured result
  return {
    response,
    prevResponse,
    usageResponse,
    usagePrevResponse,
    summary: {
      interval_spend: intervalSpend,
      previous_interval_spend: previousIntervalSpend,
      mtd_spend: mtdSpend,
      mtd_delta_spend: mtdDeltaSpend,
      yesterday_spend: yesterdaySpend,
      yesterday_delta_spend: yesterdayDeltaSpend,
    },
  };
};

export const getShowbackCombineValues = (
  apiResponse = [],
  showback = {},
  totalResponse = undefined,
  range,
  apiCallMapping,
) => {
  const {
    response = {},
    prevResponse = {},
    usageResponse = {},
    usagePrevResponse = {},
    summary = {},
  } = splitResultBasedOnRange(apiResponse, range, apiCallMapping);
  let { cost_distribution } = getShowbackDistributionCost(response, showback, prevResponse, totalResponse);

  const { cost_distribution: usage_cost_distribution } = getShowbackDistributionCost(
    usageResponse,
    showback,
    usagePrevResponse,
    totalResponse,
  );
  // Update cost_distribution for valid showback values
  Object.keys(response.data || {}).forEach(item => {
    const key = item || 'Unallocated';

    if (!totalResponse?.allowed_all_showback_values && !(totalResponse?.showback_values || []).includes(key)) {
      return;
    }

    cost_distribution = {
      ...cost_distribution,
      [key]: {
        ...(response?.data?.[key] || {}),
        prev_total: prevResponse?.data?.[key]?.total || 0,
      },
    };
  });

  if (cost_distribution?.['Unallocated']?.total === 0) {
    delete cost_distribution['Unallocated'];
  }

  if (usage_cost_distribution?.['Unallocated']?.total === 0) {
    delete usage_cost_distribution?.['Unallocated'];
  }

  // Helper to format filter data
  const formatFilterData = distribution =>
    Object.keys(distribution).map(item => ({
      value: item,
      label: item || 'Unallocated',
      cost: distribution[item]?.total || 0,
    }));

  return {
    totalResponse,
    data: { ...showback, cost_distribution, usage_cost_distribution },
    summary,
    filterData: formatFilterData(cost_distribution),
    filterUsageData: formatFilterData(usage_cost_distribution),
  };
};

export const isEndDateYesterday = date_end => date_end === YESTERDAY.format(FILTER_DATE_FORMAT);

export const getCalculateSummary = (summary = {}, values = [], totalResponse = {}) => {
  const { allowed_all_showback_values = false, showback_values = [] } = totalResponse;
  // Initialize data objects
  let response = { ...summary?.interval_spend };
  let prevResponse = { ...summary?.previous_interval_spend };
  let mtd = { ...summary?.mtd_spend };
  let prevMTD = { ...summary?.mtd_delta_spend };
  let yesterday = { ...summary?.yesterday_spend };
  let prevYesterday = { ...summary?.yesterday_delta_spend };

  // Helper function to reset objects
  const resetData = () => {
    response = {};
    prevResponse = {};
    mtd = {};
    prevMTD = {};
    yesterday = {};
    prevYesterday = {};
  };

  const updateData = (key, source) => {
    response[key] = source?.interval_spend?.[key] || 0;
    prevResponse[key] = source?.previous_interval_spend?.[key] || 0;
    mtd[key] = source?.mtd_spend?.[key] || 0;
    prevMTD[key] = source?.mtd_delta_spend?.[key] || 0;
    yesterday[key] = source?.yesterday_spend?.[key] || 0;
    prevYesterday[key] = source?.yesterday_delta_spend?.[key] || 0;
  };

  // Handle totalResponse
  if (!isEmpty(totalResponse) && !allowed_all_showback_values) {
    resetData();

    showback_values.forEach(item => {
      const key = item === 'Unallocated' ? '' : item;
      updateData(key, summary);
    });
  }

  const excludeData = values.filter(item => item.type === 'Exclude');
  const includeData = values.filter(item => item.type === 'Include');

  if (includeData.length) {
    resetData();
    includeData.forEach(({ value }) => {
      const newValue = value === 'Unallocated' ? '' : value;
      updateData(newValue, summary);
    });
  }

  if (excludeData?.length) {
    excludeData.forEach(({ value }) => {
      const newValue = value === 'Unallocated' ? '' : value;
      delete response[newValue];
      delete prevResponse[newValue];
      delete mtd[newValue];
      delete prevMTD[newValue];
      delete yesterday[newValue];
      delete prevYesterday[newValue];
    });
  }

  // Calculate totals
  const calculateTotal = data => Object.values(data).reduce((acc, curr) => acc + curr, 0);
  const interval_spend = calculateTotal(response);
  const previous_interval_spend = calculateTotal(prevResponse);
  const mtd_spend = calculateTotal(mtd);
  const mtd_delta_spend = calculateTotal(prevMTD);
  const yesterday_spend = calculateTotal(yesterday);
  const yesterday_delta_spend = calculateTotal(prevYesterday);

  return {
    interval_spend,
    previous_interval_spend,
    mtd_delta_spend,
    mtd_spend,
    yesterday_spend,
    yesterday_delta_spend,
  };
};

export const getTabName = activeTab => {
  return COST_ANALYSIS_DIMENSION_NAMES[activeTab];
};

export const manageDuplicateData = (tabData = {}, activeTab = {}, labelForValue = false) => {
  const granularNull = tabData?.['null']?.granular || {};
  const granularEmpty = tabData?.['']?.granular || {};
  const summaryNull = tabData?.['null']?.summary || {};
  const summaryEmpty = tabData?.['']?.summary || {};

  const mergedGranular = {},
    mergedSummary = {};
  Object.keys(granularNull).forEach(key => {
    mergedGranular[key] = (mergedGranular?.[key] || 0) + (granularNull?.[key] || 0);
  });
  Object.keys(granularEmpty).forEach(key => {
    mergedGranular[key] = (mergedGranular?.[key] || 0) + (granularEmpty?.[key] || 0);
  });
  Object.keys(summaryNull).forEach(key => {
    mergedSummary[key] = (mergedSummary?.[key] || 0) + (summaryNull?.[key] || 0);
  });
  Object.keys(summaryEmpty).forEach(key => {
    mergedSummary[key] = (mergedSummary?.[key] || 0) + (summaryEmpty?.[key] || 0);
  });

  return {
    key: 'null',
    name: getDimensionLabel(activeTab, labelForValue),
    total: (tabData['null']?.total || 0) + (tabData['']?.total || 0),
    ...mergedGranular,
    ...mergedSummary,
  };
};

export const getActiveTabData = (data = {}, activeTab, params, isKeyValueGraphData) => {
  if (!isTabShowingKeyValue(activeTab) || isKeyValueGraphData) {
    return data;
  }

  const key = getDimensionKey(activeTab);
  let filter = params?.[`${key}_keys`] || [];
  const and_filter = params?.[`${key}_keys_and`] || [];

  if (!filter.length && and_filter.length) {
    filter = and_filter;
  }

  const newLabel = `${key}_value`;

  if (!filter?.length) {
    return data;
  }

  let result = {};
  const includeParams = uniq(filter.filter(param => param.type === 'Include').map(param => param.value));

  if (includeParams.length > 0) {
    // If there are Include parameters, add only those keys to the result
    includeParams.forEach(key => {
      if (isNullOrEmpty(key)) {
        result = {
          ...result,
          '': data[''],
          null: data['null'],
        };
      } else if (data[key] !== undefined) {
        result[key] = data[key];
      }
    });
  } else {
    // If no Include parameters, start with a copy of the original object
    result = { ...data };

    // Apply Exclude parameters
    const excludeParams = uniq(
      filter.filter(param => param.type === 'Exclude' && !param[newLabel]).map(param => param.value),
    );
    excludeParams.forEach(key => {
      if (isNullOrEmpty(key)) {
        delete result[''];
        delete result['null'];
      } else if (data[key] !== undefined) {
        delete result[key];
      }
    });
  }

  return result;
};

export const getExpandedActiveTabData = (data = {}, activeTab, params, key, label) => {
  const newLabel = `${label}_value`;

  if (!isTabShowingKeyValue(activeTab)) {
    return data;
  }

  let filter = params?.[`${label}_keys`] || [];
  const and_filter = params?.[`${label}_keys_and`] || [];

  if (!filter.length && and_filter.length) {
    filter = and_filter;
  }

  filter = filter.filter(item => item.value === key && item[newLabel]);

  if (!filter?.length) {
    return data;
  }

  let result = {};
  // Check for Include parameters
  const includeParams = filter.filter(param => param.type === 'Include').map(param => param[newLabel]);

  if (includeParams.length > 0) {
    // If there are Include parameters, add only those keys to the result
    includeParams.forEach(value => {
      if (isNullOrEmpty(value)) {
        result = {
          ...result,
          '': data[''],
          null: data['null'],
        };
      } else if (data[value] !== undefined) {
        result[value] = data[value];
      }
    });
  } else {
    // If no Include parameters, start with a copy of the original object
    result = { ...data };

    // Apply Exclude parameters
    const excludeParams = filter.filter(param => param.type === 'Exclude').map(param => param[newLabel]);
    excludeParams.forEach(value => {
      if (isNullOrEmpty(value)) {
        delete result[''];
        delete result['null'];
      } else if (data[value] !== undefined) {
        delete result[value];
      }
    });
  }

  return result;
};

const lockCostTypes = [
  'pricing_public_on_demand_cost',
  'nops_line_item_net_unblended_cost',
  'nops_line_item_net_amortized_cost',
  'nops_pricing_net_public_on_demand_cost',
];
const dimensions = [...BC_PLUS_TABS, 'label', 'cost_allocation_tag'];

export const isDimensionIncluded = dimension => {
  return dimensions.includes(dimension);
};

export const getAllLockDimension = () => {
  let lockDimensions = [];

  dimensions.forEach(item => {
    lockDimensions = [...lockDimensions, item, `exclude_${item}`, `like_${item}`, `not_like_${item}`];
  });

  return lockDimensions;
};

export const getLockedReports = (report, isShowback) => {
  let hasFilterData = false;
  const lockDimensions = getAllLockDimension();

  for (const [key, value] of Object.entries(report)) {
    if (lockDimensions.includes(key) && Array.isArray(value) && value.length > 0) {
      hasFilterData = true;

      break;
    }
  }

  const shouldLockReport =
    (!isShowback && (lockCostTypes.includes(report.cost_type) || isDimensionIncluded(report.dimension))) ||
    (isShowback && report.percentage_allocation_type === 'CUSTOM_BY_SPEND') ||
    hasFilterData;

  return !!shouldLockReport;
};

/**
 * getKeyOfEligibleGraph
 * Get the key for the eligible graph data based on the active tab and params.
 * @param {string} activeTab - The active tab.
 * @param {object} params - The params object.
 * @returns {string} The key for the eligible graph data.
 */
export const getKeyOfEligibleGraph = (activeTab, params) => {
  if (!isTabShowingKeyValue(activeTab)) {
    return '';
  }

  const dimensionKey = COST_ANALYSIS_FILTER_DIMENSION[activeTab];
  const includedItems = params?.[dimensionKey] ?? [];
  const includedAndItems = params?.[`${dimensionKey}_and`] ?? [];
  let result = includedAndItems.length ? includedAndItems : includedItems;
  result = result.filter(item => item.type === 'Include');

  let eligibleKey = '';
  const uniqueKey = uniq(result.map(item => item.value));

  if (uniqueKey.length === 1) {
    eligibleKey = uniqueKey[0];
  }

  return isNullOrEmpty(eligibleKey) ? '' : eligibleKey;
};

/**
 * Get showback filter data based on selected showback values and active showback data.
 *
 * @param {Array} showbackValues - List of showback values with type 'Include' or 'Exclude'.
 * @param {Object} activeShowback - The active showback object containing combined values and cost distribution.
 * @param {Object} totalResponse - The total response object containing allowed showback values.
 * @returns {Object} Filter data with showback values.
 */
export const getShowbackFilterData = (showbackValues, activeShowback, totalResponse) => {
  const list = showbackValues ?? [];

  // Case 1: If `activeShowback.id` is missing OR they have full access & list is empty → return `{}`.
  if (!activeShowback?.id || (!list.length && totalResponse?.allowed_all_showback_values)) {
    return {};
  }

  // Case 2: If `list` is empty & they do NOT have full access → fetch from `totalResponse.showback_values`
  if (!list.length && !totalResponse?.allowed_all_showback_values) {
    if (totalResponse?.showback_values?.length) {
      return {
        showback_value: totalResponse.showback_values.map(item => ({
          value: item === 'Unallocated' ? '' : item,
          type: 'Include',
        })),
      };
    }

    return { showback_value: undefined };
  }

  const combinedValues = activeShowback?.combined_values ?? [];
  let showBackNamesInclude = list.filter(item => item.type === 'Include');
  let showbackNameExclude = list.filter(item => item.type === 'Exclude');

  // Expand 'Include' values with their combined values
  showBackNamesInclude = showBackNamesInclude.flatMap(item => {
    const rec = combinedValues.find(combine => combine.name === item.value);

    return rec
      ? [{ ...item }, ...rec.combined_values.map(value => ({ type: 'Include', value, label: value }))]
      : [item];
  });

  // If there are only 'Exclude' values, generate the 'Include' list dynamically
  if (!showBackNamesInclude.length && showbackNameExclude.length) {
    let showbackExcludeNames = showbackNameExclude.map(item => item.value);

    showbackExcludeNames = showbackExcludeNames.flatMap(item => {
      const rec = combinedValues.find(combine => combine.name === item);

      return rec ? [item, ...rec.combined_values] : [item];
    });

    showbackNameExclude = uniq(showbackExcludeNames);

    // Add remaining eligible showback values
    const allShowbackValues = Object.keys(activeShowback?.cost_distribution || {});
    showBackNamesInclude = allShowbackValues
      .filter(item => !showbackNameExclude.includes(item))
      .map(value => ({ value, label: value, type: 'Include' }));
  }

  return {
    showback_value: showBackNamesInclude.map(({ value }) => ({ value, type: 'Include' })),
  };
};

/**
 * Returns the appropriate item count based on window width
 * @param {number} width - Window width in pixels
 * @returns {number} Count value for items per page
 */
export const getResponsiveCount = width => {
  if (width < 1024) {
    return 10;
  }

  if (width < 1400) {
    return 20;
  }

  if (width < 1600) {
    return 30;
  }

  return 50;
};

/**
 * Returns the appropriate legend spacing based on window width
 * @param {number} width - Window width in pixels
 * @returns {number} Legend spacing value
 */
export const getResponsiveLegendSpace = width => {
  if (width <= 1024) {
    return 10;
  }

  if (width <= 1400) {
    return 7;
  }

  if (width <= 1600) {
    return 6;
  }

  return 3;
};
