import axiosObj from './config';
import { getURLParams } from './all-api-helper';

export function getShowbackList(params) {
  return axiosObj({
    method: 'GET',
    url: '/c/showback/showbacks/',
    params,
  });
}

export function createShowback(data) {
  return axiosObj({
    method: 'POST',
    url: '/c/showback/showbacks/',
    data,
  });
}

export function updateShowbackList(showback_ids) {
  return axiosObj({
    method: 'POST',
    url: '/c/showback/showbacks/set_active_showbacks/',
    data: {
      showback_ids,
    },
  });
}

export function getTagKeysList(params) {
  return axiosObj({
    method: 'GET',
    url: '/c/showback/showbacks/tag_keys_list/',
    params,
  });
}

export function getCostAllocationTagList(params) {
  return axiosObj({
    method: 'GET',
    url: '/c/showback/showbacks/cost_allocation_tag_keys_list/',
    params,
  });
}

export function getTotalCost(params) {
  return axiosObj({
    method: 'GET',
    url: '/c/showback/showbacks/total_cost/',
    params: getURLParams(params),
  });
}

export function getTotalCostForDetail({ id, params }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/showback_cost/`,
    params: getURLParams(params),
  });
}

export function getShowbackValuesDailyCost({ id, params }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/showback_values_daily_cost/`,
    params: getURLParams(params, true),
  });
}

export function getShowbackByID({ id, params }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/`,
    params: getURLParams(params),
  });
}

export function editShowbackByID({ id, data }) {
  return axiosObj({
    method: 'PATCH',
    url: `/c/showback/showbacks/${id}/`,
    data,
  });
}

export function deleteShowbackByID(id) {
  return axiosObj({
    method: 'DELETE',
    url: `/c/showback/showbacks/${id}/`,
  });
}

export function getDimensionCost({ id, params = {} }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/dimension_costs/`,
    params: getURLParams(params),
  });
}

export function getDimensionDailyCost({ id, params = {} }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/dimension_daily_cost/`,
    params: getURLParams(params, true),
  });
}

export function getAllocationRuleDimensionDailyCost({ id, params = {} }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/dimension_daily_cost/`,
    params: getURLParams(params),
  });
}

export function getCloudResourceCostForShowback({ id, params = {} }) {
  return axiosObj({
    method: 'POST',
    url: `/c/showback/showbacks/${id}/dimension_costs/`,
    params: getURLParams(params),
  });
}

export function getAvailableTagValue({ id, params = {} }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/available_tag_values/`,
    params: getURLParams(params),
  });
}

export function getAvailableLabelValue({ id, params = {} }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/available_label_values/`,
    params: getURLParams(params),
  });
}

export function getAvailableCostAllocationValue({ id, params = {} }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/available_cost_allocation_tag_values/`,
    params: getURLParams(params),
  });
}

export function getCombineValuesByID(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/combined_values/`,
  });
}

export function addCombineValuesByID({ showback_id, data }) {
  return axiosObj({
    method: 'POST',
    url: `/c/showback/showbacks/${showback_id}/combined_values/`,
    data,
  });
}

export function editCombineValuesByID({ showback_id, data, id }) {
  return axiosObj({
    method: 'PUT',
    url: `/c/showback/showbacks/${showback_id}/combined_values/${id}/`,
    data,
  });
}

export function deleteCombineValuesByID({ showback_id, id }) {
  return axiosObj({
    method: 'DELETE',
    url: `/c/showback/showbacks/${showback_id}/combined_values/${id}/`,
  });
}

export function getResourcesList({ id, params }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/resources_list/`,
    params: getURLParams(params),
  });
}

export function getResourcesListAPI({ id, params }) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/resources_list_api/`,
    params: getURLParams(params),
  });
}

export function getAllocationRule(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/allocation_rules/`,
  });
}

export function addAllocationRule({ id, data }) {
  return axiosObj({
    method: 'POST',
    url: `/c/showback/showbacks/${id}/allocation_rules/`,
    data,
  });
}

export function editAllocationRule({ id, data, allocationId }) {
  return axiosObj({
    method: 'PATCH',
    url: `/c/showback/showbacks/${id}/allocation_rules/${allocationId}/`,
    data,
  });
}

export function deleteAllocationRule({ id, allocationId }) {
  return axiosObj({
    method: 'DELETE',
    url: `/c/showback/showbacks/${id}/allocation_rules/${allocationId}/`,
  });
}

export function deleteMultipleAllocationRule({ id, allocationId }) {
  return axiosObj({
    method: 'DELETE',
    url: `/c/showback/showbacks/${id}/delete_allocation_rules_by_id/`,
    data: {
      allocation_rule_ids: allocationId,
    },
  });
}

export function reorderAllocationRule({ id, source_rule_id, target_rule_id }) {
  return axiosObj({
    method: 'POST',
    url: `/c/showback/showbacks/${id}/allocation_rules/${source_rule_id}/move_before_rule/`,
    data: {
      target_rule_id: target_rule_id,
    },
  });
}

//--------------------Allocation Rule with Dimension Filter--------------------
export function getShowbackDimensionFilters(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/showback/showbacks/${id}/dimension_filters/`,
  });
}

export function addShowbackDimensionFilter({ id, data }) {
  return axiosObj({
    method: 'POST',
    url: `/c/showback/showbacks/${id}/dimension_filters/`,
    data,
  });
}

export function editShowbackDimensionFilter({ id, data, allocationId }) {
  return axiosObj({
    method: 'PATCH',
    url: `/c/showback/showbacks/${id}/dimension_filters/${allocationId}/`,
    data,
  });
}

export function deleteShowbackDimensionFilter({ id, allocationId }) {
  return axiosObj({
    method: 'DELETE',
    url: `/c/showback/showbacks/${id}/dimension_filters/${allocationId}/`,
  });
}
