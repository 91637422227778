import * as configcat from 'configcat-js';
import React from 'react';
import moment from 'moment';
import { getEnv, PRODUCTION_ENV } from 'utils/env';

const currentEnv = getEnv();
const CC_UAT_KEY = 'ySnZCGuW90-CIrk3UtogNw/EcsMInxY-0Go-nzTGi7X-A';
const CC_PRODUCTION_KEY = 'ySnZCGuW90-CIrk3UtogNw/bkxhzAqwNUqqvAvFsOricg';
const CC_KEY = currentEnv === PRODUCTION_ENV ? CC_PRODUCTION_KEY : CC_UAT_KEY;

export const configClient = configcat.getClient(CC_KEY, configcat.PollingMode.AutoPoll, {
  pollIntervalSeconds: moment.duration(2, 'hours').asSeconds(),
  logger: configcat.createConsoleLogger(
    currentEnv === PRODUCTION_ENV ? configcat.LogLevel.Off : configcat.LogLevel.Error,
  ),
});

const FeatureFlagsContext = React.createContext(configClient);

export const FeatureFlagsProvider = FeatureFlagsContext.Provider;
export const FeatureFlagsConsumer = FeatureFlagsContext.Consumer;
export default FeatureFlagsContext;
