import React from 'react';
import ReactDOM from 'react-dom';
import numeral from 'numeral';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import forIn from 'lodash/forIn';
import uniq from 'lodash/uniq';
import moment from 'moment';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import { createSelectable } from 'react-selectable';
import { changeDateToStringFormat, FILTER_DATE_FORMAT, getFilterParams, getFilterQuery } from './filter';
import { countDecimals, getPercentage } from './number';
import { logMyErrors } from './request';
import { ALL_CLOUD_PLATFORM_KEYS } from './constants';
import { sortDays, DAYS_SHORT } from 'containers/Essentials/Scheduler/helper';
import { Anchor } from 'components/Custom';
import { WEEK_LABEL_SHORT } from 'components/Schedules/constants';
import { getVPCNames } from 'api/global';
import { VerifyIcon } from 'assets/images/common';
import { COST_ANALYSIS_DIMENSION_KEYS } from 'containers/CloudResourceCost-API/constants';

export const convertStringDateToMoment = (date1, date2) => {
  if (typeof date1 !== 'string') {
    return date1;
  }

  return date1 ? moment(date1, FILTER_DATE_FORMAT) : cloneDeep(date2);
};

export const selectedIsMonth = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return false;
  }

  return moment(endDate, FILTER_DATE_FORMAT).diff(moment(startDate, FILTER_DATE_FORMAT), 'months', true) >= 2;
};

export const getFilteredDates = (startDate = null, endDate = null, defaultRange) => {
  if (!moment(startDate, FILTER_DATE_FORMAT).isValid() || !moment(endDate, FILTER_DATE_FORMAT).isValid()) {
    return [];
  }

  const dateArray = [];
  const tmpDate = moment(startDate, FILTER_DATE_FORMAT);
  let diffIn = 'days';

  if (selectedIsMonth(tmpDate, endDate) && !defaultRange) {
    diffIn = 'months';
  }

  while (tmpDate.format(FILTER_DATE_FORMAT) <= endDate.format(FILTER_DATE_FORMAT)) {
    dateArray.push(tmpDate.format(FILTER_DATE_FORMAT));
    tmpDate.add(1, diffIn);
  }

  return dateArray;
};

export const setDefaultFilterData = props => {
  const {
    filters = {},
    workload = '',
    tagData = {},
    cit = [],
    selectedAutoScaling = [],
    parentFilters = {
      must: [],
      must_not: [],
      _multiple_conditions: true,
    },
  } = props;
  let filterInclude = cloneDeep(parentFilters);

  if (!isEmpty(tagData)) {
    filterInclude = {
      ...filterInclude,
      ...tagData,
    };
  }

  if (cit.length) {
    filterInclude = {
      ...filterInclude,
      must: [
        ...filterInclude['must'],
        {
          'nested:tags_kv:tags_kv.key': ['aws:cloudformation:stack-name'],
          'nested:tags_kv:tags_kv.value': cit,
        },
      ],
    };
  } else {
    //
  }

  if (selectedAutoScaling.length) {
    filterInclude = {
      ...filterInclude,
      must: [
        ...filterInclude['must'],
        {
          'name.keyword': selectedAutoScaling,
        },
        {
          is_as: [true],
        },
      ],
    };
  } else {
    //
  }

  const fData = [];
  forIn(filters, (value, key) => {
    if (!value?.length) {
      return null;
    }

    fData.push({
      [key]: value,
    });
  });

  if (fData.length) {
    filterInclude['must'] = [...filterInclude['must'], ...fData];
  }

  const param = {};
  param.filters = filterInclude;
  param.workload = workload || null;

  return param;
};

export const checkIsEqual = (status, data1, data2) => {
  return status ? isEqual(data1, data2) : !isEqual(data1, data2);
};

export const getResourceName = id => {
  if (!id) {
    return '';
  }

  if (id.startsWith('CloudWatch usage for arn')) {
    const parts = id.split(':').pop().split('/');

    return parts[parts.length - 1];
  }

  if (id.startsWith('arn:aws')) {
    if (id.includes(':lambda') && id.includes(':function')) {
      return id.split('function:')[1].split(':')[0];
    }

    const separator = id.includes('/') ? '/' : ':';
    const parts = id.split(separator);

    return parts[parts.length - 1];
  }

  return id;
};

export const getResourceType = id => {
  if (isEmpty(id)) {
    return '';
  }

  if (id.split(':')[2]) {
    return id.split(':')[2];
  } else {
    return id;
  }
};

export const getTagsData = (data = {}, key = 'keys') => {
  const nestedData = get(data, `arp_tags_${key}_reverse.arp_tags_${key}_cost_nested`);
  const violationNestedData = get(data, `arp_tags_${key}_reverse.arp_tags_${key}_violations_nested`, {});
  const cloudPlatform = data.cloudPlatform || '';

  return {
    name: get(data, 'key', ''),
    count: get(data, 'doc_count', 0),
    totalCost: get(nestedData, `arp_tags_${key}_timeframe_cost.summary.value`, 0),
    violationCount: get(violationNestedData, `violations_details.violations_counts_sum.value`, 0),
    dailyPercentage: getPercentage(
      get(nestedData, `arp_tags_${key}_1d.summary.value`, 0),
      get(nestedData, `arp_tags_${key}_1d_delta.summary.value`, 0),
    ),
    weeklyPercentage: getPercentage(
      get(nestedData, `arp_tags_${key}_7d.summary.value`, 0),
      get(nestedData, `arp_tags_${key}_7d_delta.summary.value`, 0),
    ),
    monthlyPercentage: getPercentage(
      get(nestedData, `arp_tags_${key}_30d.summary.value`, 0),
      get(nestedData, `arp_tags_${key}_30d_delta.summary.value`, 0),
    ),
    cloudPlatform,
  };
};

export const getProjectName = (index, projects = []) => {
  const projectId = index.split(':')[0].split('_')[1];
  const projectName = projects.find(x => +x.id === +projectId);

  return {
    name: (projectName && (projectName.name || projectName.value)) || '',
    id: projectId,
  };
};

export const getClientEmailList = (emailList = [], allClients = []) => {
  const newEmailList = cloneDeep(emailList);
  allClients.forEach(d => {
    d.admin.length &&
      d.admin.forEach(a => {
        const rec = newEmailList.find(t => t.value === a.email);

        if (!rec) {
          newEmailList.push({ value: a.email, label: a.email });
        }
      });
  });

  return newEmailList;
};

export const convertByteToMB = (bytes = 0) => {
  if (bytes <= 0) {
    return 0;
  }

  return Number((bytes / (1024 * 1024)).toFixed(2));
};

export const FORMAT_RAM_SIZE = (value = 0) => {
  let size;
  const b = Math.abs(value);
  const kb = numberFix(b / 1024);
  const mb = numberFix(b / 1024 / 1024);
  const gb = numberFix(b / 1024 / 1024 / 1024);
  const tb = numberFix(b / 1024 / 1024 / 1024 / 1000);

  if (tb > 1) {
    size = tb + ' TB';
  } else if (gb > 1) {
    size = gb + ' GB';
  } else if (mb > 1) {
    size = mb + ' MB';
  } else if (kb > 1) {
    size = kb + ' KB';
  } else {
    size = b + ' Bytes';
  }

  return value < 0 ? '-' + size : size;
};

export const numberFix = (num = 0, decimals = 2) => {
  if (isNaN(Number(num))) {
    return 0;
  }

  return Number(Number(num)?.toFixed(decimals));
};

export const addMonthFilter = (service, rule) => {
  return (
    (rule === 's3_underutilized' && service === 's3') ||
    (rule === 'efs_low_utilization' && service === 'efs') ||
    (rule === 'ecs_low_utilization' && service === 'ecs') ||
    (rule === 'dynamodb_low_utilization' && service === 'dynamodb') ||
    (rule === 'ec2_low_network_utilization' && service === 'ec2') ||
    (rule === 'underutilized_resources' && service === 'ec2') ||
    (rule === 'iops_performance' && service === 'ebs') ||
    (rule === 'iops_performance' && service === 'rds') ||
    (rule === 'instance_recommendation' && service === 'rds') ||
    (rule === 'instance_recommendation_rightsizing' && service === 'ec2') ||
    (rule === 'redshift_low_node_utilization' && service === 'redshift')
  );
};

export const numberWithCommas = x => {
  const isNumber = numeral(x).format(0, 0.0) !== 'NaN';

  return isNumber ? numeral(x).format('0,0') : '0';
};

export const numberWithCommasAndDecimal = x => {
  const isNumber = numeral(x).format(0, 0.0) !== 'NaN';

  return isNumber ? numeral(x).format('0,0.00') : '0';
};

export const numberFormatter = (x, digits = 2) => {
  return (Number(x) || 0).toLocaleString(undefined, {
    maximumFractionDigits: digits,
  });
};

export const getTypeName = type => {
  switch (type) {
    case 'log':
      return 'Loggroup';
    case 'loggroup':
      return 'Loggroup';
    case 'security_group':
      return 'Security group';
    case 'iam_role':
      return 'IAM role';
    case 'ebs_snapshot':
      return 'EBS snapshot';
    case 's3':
      return 'S3';
    case 'subnet':
      return 'Subnet';
    case 'lambda':
      return 'Lambda';
    case 'ebs':
      return 'EBS';
    case 'vpc':
      return 'VPC';
    case 'ec2':
      return 'EC2';
    case 'eip':
      return 'EIP';
    case 'dynamodb':
      return 'DynamoDB';
    case 'nat':
      return 'NAT Gateway';
    case 'iam':
      return 'IAM';
    case 'ecs':
      return 'ECS';
    case 'rds':
      return 'RDS';
    case 'rds_cluster':
      return 'RDS Cluster';
    case 'elb':
      return 'ELB';
    case 'ct_trail':
      return 'Cloud trail';
    case 'efs':
      return 'EFS';
    case 'elasticache':
      return 'Elastic cache';
    case 'redshift':
      return 'Redshift';
    case 'client-vpn':
      return 'Client VPN';
    case 'es':
      return 'Elastic search';
    case 'ds':
      return 'Directory service';
    case 'eks':
      return 'EKS';
    case 'kms':
      return 'Key Management Service';
    case 'elasticloadbalancing':
      return 'Elastic load balancing';
    case 'disk_storage':
      return 'Disk';
    case 'network_interfaces':
      return 'Network interface';
    default:
      return type;
  }
};

export const getRegionName = region => {
  switch (region) {
    case 'APE1':
      return 'ap-east-1';
    case 'APN1':
      return 'ap-northeast-1';
    case 'APN2':
      return 'ap-northeast-2';
    case 'APS1':
      return 'ap-southeast-1';
    case 'APS2':
      return 'ap-southeast-2';
    case 'APS3':
      return 'ap-south-1';
    case 'CAN1':
      return 'ca-central-1';
    case 'EUN1':
      return 'eu-north-1';
    case 'EUC1':
      return 'eu-central-1';
    case 'EUW2':
      return 'eu-west-2';
    case 'EUW3':
      return 'eu-west-3';
    case 'MES1':
      return 'me-south-1';
    case 'SAE1':
      return 'sa-east-1';
    case 'USE1':
      return 'us-east-1';
    case 'USE2':
      return 'us-east-2';
    case 'USW1':
      return 'us-west-1';
    case 'USW2':
      return 'us-west-2';
    case 'EU':
      return 'eu-west-1';
    case 'CPT':
      return 'af-south-1';
    default:
      return 'us-east-1';
  }
};

export const handleGlobalNotificationFunction = (res, action = () => {}, dispatch) => {
  try {
    const payload = {
      type: 'error',
      show: true,
      message: getErrorMessage(res),
    };
    dispatch ? dispatch(action(payload)) : action(payload);
  } catch (e) {
    logMyErrors('global:', e);
  }
};

export const getErrorMessage = (res, defaultMessage = undefined) => {
  switch (res?.status) {
    case 400:
    case 409:
    case 424: {
      let error = '';

      if (!isEmpty(res?.data)) {
        forIn(res.data, value => {
          if (Array.isArray(value)) {
            forIn(value, err => {
              if (!isEmpty(err)) {
                error = [
                  ...error,
                  typeof err === 'object'
                    ? err?.[0] || Object.values(err)?.[0]?.[0] || Object.values(err)?.[0] || "It's a bad request."
                    : err,
                ];
              }
            });
          } else {
            error =
              error +
              `${
                typeof value === 'object' ? value?.[0] || Object.values(value)?.[0] || "It's a bad request." : value
              } `;
          }
        });
      }

      if (Array.isArray(error)) {
        error = uniq(error).join(', ');
      }

      return (
        (res?.data?.length && res?.data?.[0]) ||
        res?.data?.error ||
        res?.data?.message ||
        res?.data?.non_field_errors?.[0] ||
        error ||
        `It's a bad request.`
      );
    }

    case 403:
      return (
        res?.data?.detail ||
        res?.data?.message ||
        res?.data?.error ||
        'Check permission or Please confirm your email address is verified.'
      );
    case 404:
      return 'The requested item was not found.';
    case 500:
    case 503:
    case 504:
      return res?.data?.detail || res?.data?.message || res?.data?.error || 'Internal server error. Try after a while.';
    default:
      return defaultMessage || 'Something went wrong.';
  }
};

export const IsJsonValid = (str, format = {}) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return format;
  }
};

export const IsURIValid = URI => {
  try {
    return decodeURIComponent(URI);
  } catch (e) {
    return '';
  }
};

export const returnTypeForAPI = (filters, workload = '*') => {
  return isEqual({ must: [], must_not: [], _multiple_conditions: true }, filters) && workload === '*'
    ? 'cached'
    : 'query';
};

export const checkBucketName = bucketName => {
  const bucketRGEX = new RegExp(/(?=^.{3,63}$)/);
  const bucketRGEX1 = new RegExp(/(^(\d+\.)+\d+$)/);

  const bucketRGEX2 = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])$)/,
  );

  const bucketEGEX3 = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])$)/,
  );

  if (bucketName) {
    const result = bucketRGEX.test(bucketName);
    const result1 = bucketRGEX1.test(bucketName);
    const result2 = bucketRGEX2.test(bucketName);
    const result3 = bucketEGEX3.test(bucketName);
    let msg = '';

    if (!(result && !result1 && result2 && result3)) {
      msg = '';
      msg += result ? '' : 'Must be at least 3 and no more than 63 characters long.';
      msg += result1 ? ' Must not be formatted as an IP address (for example, 192.168.5.4).\n' : '';
      msg +=
        result2 || result3
          ? ''
          : ' Must not contain uppercase characters or underscores, must start/end with a lowercase letter or number only and can be a series of one or more labels.\n';
    }

    return msg;
  }
};

export const checkPasswordValidation = (password, personalInfoList = [], company_name = '') => {
  if (password === '') {
    return {
      upperLowercase: true,
      numDigits: true,
      specialChar: true,
      personalInfo: true,
      companyInfo: true,
      maxLength: true,
    };
  }

  const regexNum = /\d/;
  const regexLowerUppercase = /(?=.*[a-z])(?=.*[A-Z])/;
  const regexSpecialCharacters = /(?=.*[!@#$%^&*])/;

  const passwordValidation = {
    upperLowercase: false,
    numDigits: false,
    specialChar: false,
    maxLength: true,
    personalInfo: true,
    companyInfo: true,
  };

  if (regexLowerUppercase.test(password)) {
    passwordValidation['upperLowercase'] = true;
  }

  if (regexNum.test(password)) {
    passwordValidation['numDigits'] = true;
  }

  if (regexSpecialCharacters.test(password)) {
    passwordValidation['specialChar'] = true;
  }

  if (password.length > 26) {
    passwordValidation['maxLength'] = false;
  }

  personalInfoList.forEach(pInfo => {
    if (pInfo !== '' && password.toLowerCase().includes(pInfo.toLowerCase())) {
      passwordValidation['personalInfo'] = false;
    }
  });

  if (company_name !== '' && password.toLowerCase().includes(company_name.toLowerCase())) {
    passwordValidation['companyInfo'] = false;
  }

  return passwordValidation;
};

export const getFilterPayload = data => {
  const searchString = getFilterQuery(data);
  const filters = getFilterParams(data);

  const newFilter = setDefaultFilterData({
    filters,
    tagData: data.tagData,
    cit: data.cit,
    selectedAutoScaling: data.selectedAutoScaling,
  }).filters;

  if (data?.is_ri) {
    newFilter['must_not'] = [...newFilter['must_not'], { is_ri: [true] }];
  }

  newFilter['must'] = [...newFilter['must']];

  return {
    searchString,
    filter: {
      ...newFilter,
    },
  };
};

export const isEmptyString = str => {
  return !str || !str.length || !str.trim();
};

export const getDiffDays = (dateStr, dateFormat, uniteOfTime) => {
  if (!dateStr) {
    return 0;
  }

  const date = new moment(dateStr, dateFormat);

  return date.diff(moment(new Date()).utc(), uniteOfTime);
};

export const pivotAggregation = (aggs, cloud_agg, old_agg) => {
  return aggs?.[cloud_agg]
    ? (aggs?.[cloud_agg]?.buckets || []).flatMap(b =>
        (b?.[old_agg]?.buckets || []).map(v => ({
          ...v,
          cloudPlatform: b.key,
        })),
      )
    : [];
};

export const calcCloudPlatformData = (aggs = {}, countAggregations = []) => {
  return ALL_CLOUD_PLATFORM_KEYS.map(cloudPlatform => ({
    key: cloudPlatform,
    doc_count: 0,
  })).map(el => ({
    key: el.key,
    doc_count: countAggregations.reduce((total, agg) => {
      const buckets = aggs?.[agg]?.buckets || [];
      const foundBucket = buckets.find(f => f?.key?.toString() === el.key);
      const currentCount = foundBucket?.doc_count || 0;

      return total + currentCount;
    }, 0),
  }));
};

export const keyToCloudObj = key => {
  if (!key) {
    return;
  }

  const lowerKey = key.toLowerCase();

  if (!ALL_CLOUD_PLATFORM_KEYS.includes(lowerKey)) {
    return;
  }

  return {
    id: lowerKey,
    value: lowerKey === 'azure' ? 'Azure' : 'AWS',
    cloudPlatform: lowerKey,
  };
};

export const extractViolationChanges = aggs => {
  return {
    change_log: {
      buckets: (aggs?.buckets || []).flatMap(cloudBucket =>
        (cloudBucket?.violations_changes?.violations_changes_results?.change_log?.buckets || []).map(
          changeLogBucket => ({
            ...changeLogBucket,
            cloudPlatform: cloudBucket.key,
          }),
        ),
      ),
    },
    doc_count: (aggs?.buckets || []).reduce((tot, item) => tot + item?.violations_changes?.doc_count || 0, 0),
  };
};

export const getClassNameBasedOnCloud = (configCat, cloud) => {
  if (!configCat) {
    return '';
  }

  if (cloud === 'azure') {
    return 'blueCola';
  }

  if (cloud === 'aws') {
    return 'orangePeel';
  }

  //easier for extensions;
  return 'orangePeel';
};

export const getIconBasedOnCloud = cloud => {
  if (cloud === 'azure') {
    return 'azureIcon';
  }

  if (cloud === 'aws') {
    return 'amazonIcon';
  }

  if (cloud === 'amazon') {
    return 'amazonIcon';
  }

  return 'amazonIcon';
};

export const pickHex = (color1, color2, weight) => {
  const w = weight * 2 - 1;
  const w1 = (w / 1 + 1) / 2;
  const w2 = 1 - w1;

  return [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
};

export const currencyFormatWithChar = value => {
  if (numeral(value).format(0, 0.0) === 'NaN') {
    return <span>{'$0'}</span>;
  }

  const decimals = countDecimals(value);
  const formattedValue = `$${value}`;

  const getCostValue = val => {
    if (val < 0.01) {
      return '< $0.01';
    }

    return `$${value.toString().slice(0, value.toString().indexOf('.') + 3)}`;
  };

  return decimals > 2 ? (
    <Tippy content={formattedValue} placement="right">
      <span>{getCostValue(value)}</span>
    </Tippy>
  ) : (
    <span>{`$${value.toFixed(2)}`}</span>
  );
};

export const currencyRenderer = (value = 0) => {
  const element = document.createElement('div');
  const issueElement = currencyFormatWithChar(value);
  ReactDOM.render(issueElement, element);

  return element;
};

export const getSumOfMTDCost = (data = []) => {
  let mtd = 0;

  if (!data.length) {
    return mtd;
  }

  const endMTDDate = moment().utc().isSame(moment().utc().subtract(1, 'days'), 'month')
    ? moment().utc().subtract(1, 'days')
    : moment().utc().set('date', 1);
  const startMTDDate = moment().utc().set('date', 1);
  data.forEach(item => {
    if (
      moment(item.date, 'YYYY-MM-DD').isBetween(
        changeDateToStringFormat(startMTDDate),
        changeDateToStringFormat(endMTDDate),
        null,
        '[]',
      )
    ) {
      mtd = mtd + item.cost;
    }
  });

  return mtd;
};

export const getSumOfMTDCostFromGranular = (data = {}) => {
  let mtd = 0;

  if (isEmpty(data)) {
    return mtd;
  }

  const endMTDDate = moment().utc().isSame(moment().utc().subtract(1, 'days'), 'month')
    ? moment().utc().subtract(1, 'days')
    : moment().utc().set('date', 1);
  const startMTDDate = moment().utc().set('date', 1);
  const keys = Object.keys(data);
  keys.forEach(item => {
    if (
      moment(item, 'YYYY-MM-DDTHH:mm:ssZ').isBetween(
        changeDateToStringFormat(startMTDDate),
        changeDateToStringFormat(endMTDDate),
        null,
        '[]',
      )
    ) {
      mtd = mtd + (data[item] || 0);
    }
  });

  return mtd;
};

export const getShutdownSchedule = (start = {}, end = {}) => {
  const tempDays = [...(start?.day_of_week || end?.day_of_week || [])];

  const days =
    tempDays.length === 7
      ? 'Every day'
      : tempDays
          .sort(sortDays(DAYS_SHORT))
          .map(item => WEEK_LABEL_SHORT[item])
          .join(', ');
  const startTime = `${start?.hour || '00'}:${start?.minute || '00'}`;
  const stopTime = `${end.hour || '00'}:${end.minute || '00'}`;

  const interval = `${moment(stopTime, ['HH:mm']).format(
    'hh:mm A',
  )} - ${moment(startTime, ['HH:mm']).format('hh:mm A')}`;

  const localInterval = `${moment.utc(stopTime, ['HH:mm']).local().format('hh:mm A')} - ${moment
    .utc(startTime, ['HH:mm'])
    .local()
    .format('hh:mm A')}`;

  return {
    utcSchedule: `${days}. ${interval}`,
    localSchedule: `${localInterval}`,
  };
};

export function headerHeightGetter() {
  const columnHeaderTexts = [...document.querySelectorAll('.ag-header-cell-text')];
  const clientHeights = columnHeaderTexts.map(headerText => headerText.clientHeight);

  return Math.max(...clientHeights);
}

export function headerHeightSetter(params) {
  const padding = 20;
  const height = headerHeightGetter() + padding;
  params.api.setHeaderHeight(height);
  params.api.resetRowHeights();
}

export function agGridBlurTextStyle(params) {
  if (!params?.data?.isDemoMode) {
    return {};
  }

  return {
    textShadow: '0 0 15px #000',
    filter: 'blur(5px)',
  };
}

export const getVPCByProjectId = async (filters = {}) => {
  const filteredProject = filters?.must.find(item => item?.['project.id']?.length);
  let vpcNames = await getVPCNames({
    filters: {
      must: filteredProject?.['project.id']?.length
        ? [{ 'project.id': filteredProject['project.id'] }, { 'cloud_type.keyword': ['aws'] }]
        : [{ 'cloud_type.keyword': ['aws'] }],
      must_not: [],
      _multiple_conditions: true,
    },
  });
  vpcNames =
    (vpcNames?.data?.aggregations?.vpc_names?.vpc_names_list?.buckets || []).map(vpc => ({
      key: vpc?.key || '',
      projectId: vpc.vpc_name_data.hits.hits[0]._index.split(':')[0].split('_')[1],
      name: vpc?.vpc_name_data?.hits?.hits?.[0]?._source?.name || vpc?.key,
    })) || [];

  return vpcNames || [];
};

export function isVersionOutdated(installedVersion, lastVersion) {
  if (!installedVersion || !lastVersion) {
    return false;
  }

  const installedVersionParts = installedVersion.split('.').map(Number);
  const lastVersionParts = lastVersion.split('.').map(Number);

  for (let i = 0; i < lastVersionParts.length; i++) {
    if (installedVersionParts[i] > lastVersionParts[i]) {
      return false;
    } else if (installedVersionParts[i] < lastVersionParts[i]) {
      return true;
    }
  }

  return false;
}

export const getUniqueSortedArray = arr => [...new Set(arr)].sort();

const InstanceFamily = ({ family, selectedKeys, eligible, onClick }) => {
  return (
    <li key={family} onClick={onClick}>
      <Tippy content="Eligible Instance Family" placement="bottom" delay={600} disabled={!eligible}>
        <Anchor
          className={classNames('np-button color-grey light d-flex flex-aic', {
            active: selectedKeys?.includes(family),
            'meets-requirements': eligible,
          })}
        >
          {family}
          {eligible ? <VerifyIcon /> : null}
        </Anchor>
      </Tippy>
    </li>
  );
};

export const SelectableInstanceFamily = createSelectable(InstanceFamily);

export const isValidEmail = (email = '') => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
};

export const truncateText = (text = '', maxLength = 15) =>
  text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

export const isNullOrEmpty = (value = '') => ['null', null, ''].includes(value);
export const isStringNull = (value = '') => value === 'null';

export const customDebounce = (fn, delay) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
};

export const isDemoModeActive = activeTab =>
  sessionStorage.getItem('demo-mode') === 'on' &&
  [
    COST_ANALYSIS_DIMENSION_KEYS.ACCOUNTS,
    COST_ANALYSIS_DIMENSION_KEYS.ACCOUNTS_NAME,
    COST_ANALYSIS_DIMENSION_KEYS.RESOURCES_ID,
    COST_ANALYSIS_DIMENSION_KEYS.RESOURCES_NAME,
    COST_ANALYSIS_DIMENSION_KEYS.LINE_ITEM_DESCRIPTION,
    COST_ANALYSIS_DIMENSION_KEYS.NON_RESOURCES_V2,
  ].includes(activeTab);

export const dateCustomFormatter = params => {
  if (!params.value) {
    return 'no date';
  }

  return moment(params.value).format('MMM D YYYY');
};

export const dateCustomComparator = (valueA, valueB) => {
  if (!valueA || !valueB) {
    return !valueA ? -1 : 1;
  }

  const momentA = moment(valueA, 'YYYY-MM-DD');
  const momentB = moment(valueB, 'YYYY-MM-DD');

  if (!momentA.isValid() || !momentB.isValid()) {
    return !momentA.isValid() ? -1 : 1;
  }

  return momentA - momentB;
};
