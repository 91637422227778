import React, { lazy, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { hotjar } from 'react-hotjar';
import { Switch, Route, Redirect } from 'react-router-dom';
import { DefaultRoute } from 'containers/Accounts/DefaultRoute';
import GlobalStyle from 'global-styles';
import ErrorBoundary from 'components/Common/ErrorBoundary';
import PublicRoute from 'components/PublicRoute';
import PrivateRoute from 'components/PrivateRoute';
import Cookies from 'js-cookie';

import 'styles/auth.css';
import 'styles/partner/auth.css';
import 'styles/np-404.css';
import 'styles/np-theme.css';
import 'styles/np-authentication.css';
import 'styles/np-ct.css';
import 'styles/np-calendar.css';
import 'styles/np-icons.css';

import 'tippy.js/dist/tippy.css';
import 'react-quill/dist/quill.snow.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import 'react-range-slider-input/dist/style.css';
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

import 'styles/ag-grid.css';

// Import components
const Playground = lazy(() => import('containers/Vibing/Playground'));
const BasePage = lazy(() => import('containers/BasePage'));
const SolutionProviderPage = lazy(() => import('containers/SolutionProvider'));
const PartnerBasePage = lazy(() => import('containers/Partner/BasePage'));
const PaymentsBasePage = lazy(() => import('containers/Payments/BasePage'));
const PartnersClientsBasePage = lazy(() => import('containers/PartnersClients/BasePage'));
const BillingBasePage = lazy(() => import('containers/Billing/BasePage'));
const LoginPage = lazy(() => import('containers/Accounts/Login'));
const SignUpPage = lazy(() => import('containers/Accounts/Signup'));
const ForgotPasswordPage = lazy(() => import('containers/Accounts/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('containers/Accounts/ResetPassword'));
const PasswordEmailSentPage = lazy(() => import('containers/Accounts/EmailSent'));
const PasswordResetSuccessPage = lazy(() => import('containers/Accounts/PasswordResetSuccess'));
const AppWrapper = styled.div``;

export default function App() {
  const location = window.location;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token_key');
    const cookieToken = Cookies.get('token_key');

    if (token) {
      Cookies.set('token_key', token);
    } else if (cookieToken && !token) {
      Cookies.remove('token_key');
    }
  }, [location]);

  useEffect(() => {
    hotjar.initialize(2824059, 6);
  }, []);

  return (
    <AppWrapper>
      <Helmet titleTemplate="%s - nOps" defaultTitle="nOps Dashboard">
        <meta name="description" content="nOps Dashboard" />
      </Helmet>

      <ErrorBoundary type="page">
        <Switch>
          <PublicRoute restricted={false} exact path="/accounts/signup_internal_user/" component={SignUpPage} />
          <PublicRoute restricted={false} exact path="/accounts/signup_user/" component={SignUpPage} />
          {/*<PublicRoute*/}
          {/*  restricted={false}*/}
          {/*  exact*/}
          {/*  path="/accounts/signup_partner_user/"*/}
          {/*  component={SignUpPage}*/}
          {/*/>*/}
          <PublicRoute restricted={false} exact path="/accounts/ftr/" component={SignUpPage} />
          <PublicRoute restricted={false} exact path="/accounts/forgot-password/" component={ForgotPasswordPage} />
          <PublicRoute
            restricted={false}
            exact
            path="/accounts/reset-password/confirm/:uid/:token"
            component={ResetPasswordPage}
          />
          <PublicRoute
            restricted={false}
            exact
            path="/accounts/reset-password-email-sent/"
            component={PasswordEmailSentPage}
          />
          <PublicRoute
            restricted={false}
            exact
            path="/accounts/password-reset-success/"
            component={PasswordResetSuccessPage}
          />
          <PrivateRoute path="/payments/" defaultPath="/accounts/signin/" component={PaymentsBasePage} />
          <PrivateRoute path="/partner/" defaultPath="/accounts/signin/" component={PartnerBasePage} />
          <PrivateRoute path="/switching-view/" defaultPath="/accounts/signin/" component={PartnersClientsBasePage} />
          <PrivateRoute path="/billing/" defaultPath="/accounts/signin/" component={BillingBasePage} />
          <PrivateRoute path="/v3/" defaultPath="/accounts/signin/" component={BasePage} />
          <PrivateRoute path="/playground/" defaultPath="/accounts/signin/" component={Playground} />
          <PrivateRoute path="/solution-provider/" defaultPath="/accounts/signin/" component={SolutionProviderPage} />
          <PublicRoute restricted={true} exact path="/accounts/signin/" component={LoginPage} />
          <Route exact path="/" render={() => <Redirect to="/accounts/signin/" />} />
          <Route path="/accounts/ftr_user/" render={() => <Redirect to="/accounts/ftr/" />} />
          <Route path="/client" render={() => <Redirect to="/partner/" />} />
          <Route path="*" component={DefaultRoute} />
        </Switch>
      </ErrorBoundary>

      <GlobalStyle />
    </AppWrapper>
  );
}
