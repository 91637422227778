import { COST_ANALYSIS_DIMENSION_KEYS } from 'containers/CloudResourceCost-API/constants';
import moment from 'moment';
import { TWO_WEEKS_AGO, WEEK_AGO, YESTERDAY, LAST_30_DAYS, FILTER_DATE_FORMAT } from 'utils/filter';

export const isSameMonth = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) {
    return false;
  }

  return a.month() === b.month() && a.year() === b.year();
};

export const dropDownList = () => {
  const options = [];
  let i = 1;

  do {
    options.push({
      start: moment().utc().subtract(i, 'M').startOf('M'),
      end: moment().utc().subtract(i, 'M').endOf('M'),
      text: moment().subtract(i, 'M').utc().format('MMM YYYY'),
    });
    i++;
  } while (i <= 6);

  return options;
};

export const cost_presets = [
  {
    text: '1D',
    end: YESTERDAY,
    start: YESTERDAY,
  },
  {
    text: '7D',
    end: YESTERDAY,
    start: WEEK_AGO,
  },
  {
    text: '14D',
    end: YESTERDAY,
    start: TWO_WEEKS_AGO,
  },
  {
    text: 'MTD',
    end: isSameMonth(moment().utc(), moment().utc().subtract(1, 'days'))
      ? moment().utc().subtract(1, 'days')
      : moment().utc().set('date', 1),
    start: moment().utc().set('date', 1),
  },
  {
    text: '30D',
    end: YESTERDAY,
    start: LAST_30_DAYS,
  },
  {
    text: '1M',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(1, 'M').startOf('M'),
  },
  {
    text: '3M',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(3, 'M').startOf('M'),
  },
  {
    text: '6M',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(6, 'M').startOf('M'),
  },
  {
    text: '1Y',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(12, 'M').startOf('M'),
  },
  {
    text: '3Y',
    end: moment().utc().subtract(1, 'M').endOf('M'),
    start: moment().utc().subtract(36, 'M').startOf('M'),
  },
  {
    text: 'YTD',
    end: moment().utc().subtract(1, 'days'),
    start: moment().utc().startOf('y'),
  },
  ...dropDownList(),
];

export const getGranularity = (date_start, date_end, granularity) => {
  const startMoment = new moment(date_start, FILTER_DATE_FORMAT);
  const endMoment = new moment(date_end, FILTER_DATE_FORMAT);
  // Total days in range
  const daysDiff = endMoment.diff(startMoment, 'days') + 1;
  const monthsDiff = endMoment.diff(startMoment, 'months') + 1;
  const weeksDiff = Math.ceil(daysDiff / 7);
  // Multiple Weeks: Start on Monday & End on Sunday
  const isFullWeeks = startMoment.isoWeekday() === 1 && endMoment.isoWeekday() === 7 && weeksDiff >= 1;
  // Multiple Months: Start on 1st & End on Last Day of Month
  const isFullMonths = startMoment.date() === 1 && endMoment.date() === endMoment.daysInMonth() && monthsDiff >= 1;

  // Multiple Quarters: Start on 1st of Quarter & End on Last Day of Quarter
  const isFullQuarters =
    startMoment.startOf('quarter').isSame(startMoment, 'day') &&
    endMoment.endOf('quarter').isSame(endMoment, 'day') &&
    monthsDiff % 3 === 0;
  // Check if end date is in the current month or current week
  const isEndDateInCurrentMonth = endMoment.isSame(moment(), 'month');
  const isEndDateInCurrentWeek = endMoment.isSame(moment(), 'isoWeek');

  // Validate Granularity and If the selection does not fit the expected period, downgrade to 'day'
  const isFullGranularity = {
    week: isFullWeeks || isEndDateInCurrentWeek,
    month: isFullMonths || isEndDateInCurrentMonth,
    quarter: isFullQuarters || isEndDateInCurrentMonth,
  };

  if (granularity in isFullGranularity) {
    return isFullGranularity[granularity] ? granularity : 'day';
  }

  return granularity;
};

export const FILTER_CATEGORY = {
  BILLING_COST: 'billing_cost',
  PRODUCT_SERVICE: 'product_service',
  RESOURCE_USAGE: 'resource_usage',
  TAGS: 'tags',
  COMPUTE: 'compute',
  STORAGE: 'storage',
  KUBERNETES: 'kubernetes',
};

export const FILTER_CATEGORY_LABEL = {
  [FILTER_CATEGORY.BILLING_COST]: 'Billing & Cost',
  [FILTER_CATEGORY.PRODUCT_SERVICE]: 'Product & Services',
  [FILTER_CATEGORY.RESOURCE_USAGE]: 'Resource & Usage',
  [FILTER_CATEGORY.TAGS]: 'Tags',
  [FILTER_CATEGORY.COMPUTE]: 'Compute',
  [FILTER_CATEGORY.STORAGE]: 'Storage',
  [FILTER_CATEGORY.KUBERNETES]: 'Kubernetes',
};

export const FILTERS_BY_CATEGORY = {
  [FILTER_CATEGORY.BILLING_COST]: [
    COST_ANALYSIS_DIMENSION_KEYS.COST_ENTITY,
    COST_ANALYSIS_DIMENSION_KEYS.COST_ENTITY_LEGAL_NAME,
    COST_ANALYSIS_DIMENSION_KEYS.ACCOUNTS,
    COST_ANALYSIS_DIMENSION_KEYS.ACCOUNTS_NAME,
    COST_ANALYSIS_DIMENSION_KEYS.INVOICE_SERVICE,
    COST_ANALYSIS_DIMENSION_KEYS.ITEM_TYPE,
    COST_ANALYSIS_DIMENSION_KEYS.PURCHASE_TYPE,
  ],
  [FILTER_CATEGORY.PRODUCT_SERVICE]: [
    COST_ANALYSIS_DIMENSION_KEYS.PRODUCT_SERVICES,
    COST_ANALYSIS_DIMENSION_KEYS.PRODUCT_SERVICES_SUBTYPE,
    COST_ANALYSIS_DIMENSION_KEYS.PRODUCT_REGIONS,
    COST_ANALYSIS_DIMENSION_KEYS.REGION_NAME,
    COST_ANALYSIS_DIMENSION_KEYS.AVAILABILITY_ZONE,
  ],
  [FILTER_CATEGORY.RESOURCE_USAGE]: [
    COST_ANALYSIS_DIMENSION_KEYS.USAGE_TYPES,
    COST_ANALYSIS_DIMENSION_KEYS.OPERATIONS,
    COST_ANALYSIS_DIMENSION_KEYS.ENVIRONMENT,
    COST_ANALYSIS_DIMENSION_KEYS.RESOURCES_ID,
    COST_ANALYSIS_DIMENSION_KEYS.RESOURCES_NAME,
    COST_ANALYSIS_DIMENSION_KEYS.NON_RESOURCES_V2,
    COST_ANALYSIS_DIMENSION_KEYS.LINE_ITEM_DESCRIPTION,
    COST_ANALYSIS_DIMENSION_KEYS.COST_UNIT,
  ],
  [FILTER_CATEGORY.TAGS]: [COST_ANALYSIS_DIMENSION_KEYS.TAGS, COST_ANALYSIS_DIMENSION_KEYS.COST_ALLOCATION_TAGS],
  [FILTER_CATEGORY.COMPUTE]: [
    COST_ANALYSIS_DIMENSION_KEYS.PRODUCT_INSTANCE_FAMILY,
    COST_ANALYSIS_DIMENSION_KEYS.PRODUCT_INSTANCE_TYPE_FAMILY,
    COST_ANALYSIS_DIMENSION_KEYS.PRODUCT_INSTANCE_TYPE,
    COST_ANALYSIS_DIMENSION_KEYS.COMPUTE_PROVISIONER,
    COST_ANALYSIS_DIMENSION_KEYS.COMPUTE_TYPES,
    COST_ANALYSIS_DIMENSION_KEYS.PLATFORM,
  ],
  [FILTER_CATEGORY.STORAGE]: [
    COST_ANALYSIS_DIMENSION_KEYS.STORAGE_PRODUCT,
    COST_ANALYSIS_DIMENSION_KEYS.STORAGE_PRODUCT_SUBTYPE,
    COST_ANALYSIS_DIMENSION_KEYS.STORAGE_CLASS,
    COST_ANALYSIS_DIMENSION_KEYS.STORAGE_TIER,
    COST_ANALYSIS_DIMENSION_KEYS.STORAGE_OVERHEAD,
  ],
  [FILTER_CATEGORY.KUBERNETES]: [
    COST_ANALYSIS_DIMENSION_KEYS.CLUSTER_ARN,
    COST_ANALYSIS_DIMENSION_KEYS.CLUSTER_NAME,
    COST_ANALYSIS_DIMENSION_KEYS.NODE,
    COST_ANALYSIS_DIMENSION_KEYS.PROVIDER_ID,
    COST_ANALYSIS_DIMENSION_KEYS.POD_NAMESPACE,
    COST_ANALYSIS_DIMENSION_KEYS.CONTROLLER_KIND,
    COST_ANALYSIS_DIMENSION_KEYS.CONTROLLER,
    COST_ANALYSIS_DIMENSION_KEYS.DEPLOYMENT,
    COST_ANALYSIS_DIMENSION_KEYS.POD,
    COST_ANALYSIS_DIMENSION_KEYS.SERVICES,
    COST_ANALYSIS_DIMENSION_KEYS.CONTAINER,
    COST_ANALYSIS_DIMENSION_KEYS.LABELS,
  ],
};

export const keyValueDimensionKeys = [
  'tag_keys',
  'tag_keys_and',
  'label_keys',
  'label_keys_and',
  'cost_allocation_tag_keys',
  'cost_allocation_tag_keys_and',
];

// Mapping for key-value dimension to reset opposite dimension(AND | OR)
export const keyValueDimensionMappings = {
  tag_keys_and: 'tag_keys',
  tag_keys: 'tag_keys_and',
  label_keys_and: 'label_keys',
  label_keys: 'label_keys_and',
  cost_allocation_tag_keys_and: 'cost_allocation_tag_keys',
  cost_allocation_tag_keys: 'cost_allocation_tag_keys_and',
};
