import React from 'react';
import {
  AsgEngineIcon,
  AwsInventoryIcon,
  AWSMapIcon,
  BillingAccountIcon,
  ChargebackIcon,
  CISReportIcon,
  CloudResourcesCostIcon,
  CmEngineIcon,
  CustomDashboardIcon,
  ReportIcon,
  DashboardIcon,
  DataExplorerIcon,
  HelpDocsIcon,
  HippaReportIcon,
  InstancesIcon,
  NavSecurityIcon,
  NKSIcon,
  NopsNewIcon,
  RightsizingIcon,
  RiUsageIcon,
  RuleChangesIcon,
  SchedulerIcon,
  Soc2ReportIcon,
  StorageIcon,
  SupportChatIcon,
  WarReportsIcon,
  LayerIcon,
} from 'assets/images';
import { OperationNameIcon } from 'assets/images/cost';
import { PAGE_SUBTITLE, PAGE_TITLE } from 'utils/constants';
import { ROUTES_PATH, ROUTES_PATH_V2 } from 'utils/routes';
import { hasWorkloadV1 } from 'utils/common';
import { getEnv, PRODUCTION_ENV } from 'utils/env';
import { INTEGRATION_TAB_V2, SETTINGS_TAB, USERS_TAB } from 'containers/Settings/constants';
import { getParamFromQueryString } from 'utils/queryString';

export const CUSTOM_MESSAGES = {
  set_jira_error: 'Failed to integrate Jira OAuth2.',
  access_link_error: 'Jira OAuth2 link is not configured or Invalid!',
  delete_jira: 'Successfully deleted Jira OAuth2 settings.',
  delete_jira_error: 'Failed to delete Jira OAuth2 settings!',
  set_jira_workspace_error: 'Failed to set the selected Jira Workspace',
};

const env = getEnv();
export const securityUrl = env === PRODUCTION_ENV ? '/v3/security-dashboard/45' : '/v3/security-dashboard/4845';
export const hipaaUrl = env === PRODUCTION_ENV ? '/v3/hipaa-dashboard/49' : '/v3/hipaa-dashboard/6768';
export const soc2Url = env === PRODUCTION_ENV ? '/v3/soc2-dashboard/52' : '/v3/soc2-dashboard/7115';
export const cisUrl = env === PRODUCTION_ENV ? '/v3/cis-dashboard/1337' : '/v3/cis-dashboard/10329';

export const routes = {
  dashboardLinks: [
    {
      path: '/v3/usage-report/dashboard/',
      title: 'Usage Dashboard',
      description: 'Visual summary of key metrics and data, designed for quick analysis and decision-making.',
      icon: <DashboardIcon />,
    },
    {
      path: '/v3/dashboards/',
      title: 'Dashboards',
      description:
        'Customised dashboard tailors key metrics and visuals to specific needs, offering personalized insights.',
      icon: <DashboardIcon />,
      isBCPlus: true,
    },
  ],
  showbackLinks: [
    {
      path: ROUTES_PATH.COST_ANALYSIS,
      title: 'Cost Analysis',
      description: 'Deeper analysis of cost trends over time by Showbacks.',
      icon: <CloudResourcesCostIcon />,
    },
    {
      path: ROUTES_PATH.COST_ALLOCATIONS,
      title: 'Cost Allocation',
      description: 'Give Business Context to every dollar of AWS spend via Showbacks.',
      icon: <BillingAccountIcon />,
    },
    {
      path: `${ROUTES_PATH.RECOMMENDATIONS}`,
      title: 'Recommendations',
      description: 'Control cost changes to prevent runaway costs and avoid cloud bill shock.',
      icon: <WarReportsIcon />,
      isBCPlus: true,
    },
    {
      path: ROUTES_PATH.COST_ANALYSIS_REPORTS,
      title: 'Cost Analysis Reports',
      description: 'Manage your cost analysis reports and set notifications on the go.',
      icon: <ReportIcon />,
      svgClass: 'svg-transparent',
      isBCPlus: true,
    },
    {
      path: ROUTES_PATH.COST_ANALYSIS_TARGETS,
      title: 'Cost Analysis Targets',
      description: 'Manage your cost targets',
      icon: <ChargebackIcon />,
      svgClass: 'svg-transparent',
      isBCPlus: true,
    },
    {
      path: '/v3/cost/reserved/',
      title: 'Commitment Inventory',
      description: 'View and manage RI planning, RI recommendation, RI Usage and savings plan recommendation.',
      icon: <RiUsageIcon />,
    },
    {
      path: `/v3/cost/chargebacks-center/`,
      title: 'Chargebacks Center',
      description: 'Enforce accountability for Cloud costs by charging them back to teams and projects.',
      icon: <ChargebackIcon />,
    },
    {
      path: '/v3/cost/tag-explorer/',
      title: 'Tag Explorer',
      description: 'Explore your tag competency across all of clouds to maintain hygiene.',
      icon: <OperationNameIcon />,
    },
    {
      path: '/v3/graphql-ide/',
      title: 'Data Explorer',
      description: 'Query your cloud resources with the help of nOps Data Explorer powered by GraphQL.',
      icon: <DataExplorerIcon />,
    },
    {
      path: '/v3/search/',
      title: 'Cloud Inventory',
      description: 'Query, filter and navigate all of your entire Cloud accounts and resources in one place.',
      icon: <AwsInventoryIcon />,
    },
  ],
  nSwitchLinks: [
    {
      path: '/v3/essentials/summary/',
      title: PAGE_TITLE.ESSENTIALS_SHARESAVE,
      description: PAGE_SUBTITLE.ESSENTIALS_SHARESAVE,
      icon: <BillingAccountIcon />,
      isNew: false,
    },
    {
      path: ROUTES_PATH.RIGHTSIZING_DASHBOARD,
      title: 'Rightsizing',
      description: 'Optimizing resources and expenses to align with current needs efficiently',
      icon: <RightsizingIcon />,
      isNew: false,
    },
    {
      path: ROUTES_PATH.SCHEDULER_DASHBOARD,
      title: 'Scheduler',
      description: 'Automates AWS resource scheduling, optimizes costs efficiently',
      icon: <SchedulerIcon />,
    },
    {
      path: ROUTES_PATH.IDLE_RESOURCES_DASHBOARD,
      title: 'Idle Resources',
      description: 'Regularly reviewing and stopping resources based on usage patterns',
      icon: <InstancesIcon />,
      isNew: false,
    },
    {
      path: ROUTES_PATH.STORAGE_DASHBOARD,
      title: 'Storage',
      description: 'One-click automation for Storage Optimization using EventBridge or Storage Changeset',
      icon: <StorageIcon />,
      isNew: false,
    },
    {
      path: '/v3/essentials/recommendations/',
      title: 'Recommendations',
      description: 'Control cost changes to prevent runaway costs and avoid cloud bill shock.',
      icon: <WarReportsIcon />,
      isBCPlus: true,
    },
  ],
  computeCopilotLinks: [
    {
      path: '/v3/compute-copilot/summary/',
      title: PAGE_TITLE.COMPUTE_COPILOT_SHARESAVE,
      description: PAGE_SUBTITLE.COMPUTE_COPILOT_SHARESAVE,
      icon: <BillingAccountIcon />,
      isNew: false,
    },
    {
      path: ROUTES_PATH.CM_DASHBOARD,
      title: PAGE_TITLE.CM,
      description: PAGE_SUBTITLE.CM,
      icon: <CmEngineIcon className="fill-none" />,
      isNew: false,
    },
    {
      path: ROUTES_PATH.ASG_DASHBOARD,
      title: 'Auto Scaling Groups',
      description: 'Configure your Auto Scaling Groups and reduce your costs with Compute Copilot.',
      icon: <AsgEngineIcon className="fill-none" />,
    },
    {
      path: ROUTES_PATH.EKS_DASHBOARD,
      title: 'EKS',
      description: 'Configure your EKS Clusters and reduce your costs with Compute Copilot.',
      icon: <NKSIcon />,
    },
    {
      path: ROUTES_PATH.CONTAINER_RIGHTSIZING,
      title: 'Container Rightsizing',
      description:
        'Ensure every workload runs at optimal CPU and memory levels, reducing waste and maximizing performance.',
      icon: <LayerIcon className="fill-none" />,
      isNew: true,
    },
  ],
  workloadLinks: profile => {
    const workload = [
      {
        path: '/v3/workload-v2/',
        title: 'WAFR',
        description:
          "Create workloads to group resources, get Bird's-eye view of your workload VPCs in diagram and start assessments or see reports.",
        icon: <WarReportsIcon />,
      },
      {
        path: '/v3/war/',
        title: 'WAFR Report',
        description:
          'Search, group and customize Well-Architected Framework Review findings into a report you can download and share.',
        icon: <WarReportsIcon />,
      },
      {
        path: '/v3/cost/map-migrated/',
        title: 'AWS MAP',
        description:
          'Track Cost and Usage Report (CUR) based on the migrated resources to get migrated workloads’ spend and generate appropriate credits.',
        icon: <AWSMapIcon />,
      },
      {
        path: '/v3/rules/',
        title: 'nOps Rules',
        description: 'Measure, navigate and control cost changes to prevent runaway costs and avoid cloud bill shock.',
        icon: <NopsNewIcon />,
      },
      {
        path: '/v3/rules/?tab=Change%20Management',
        title: 'nOps Rules Change Management',
        description: 'Create and manage nOps rules that automatically track changes across your cloud.',
        icon: <RuleChangesIcon />,
      },
      {
        path: '/v3/dashboard/list/',
        title: 'Custom Dashboard',
        description:
          'Build your own Well-Architected dashboard to focus on and share what’s important for you to communicate to your team.',
        icon: <CustomDashboardIcon />,
      },
    ];

    if (hasWorkloadV1(profile?.email)) {
      return [
        {
          path: '/v3/workload/',
          title: 'Workloads v1',
          description: 'Create workloads to group resources and start assessments or see reports',
          icon: <WarReportsIcon />,
        },
        ...workload,
      ];
    }

    return workload;
  },
  mapLinks: () => ({
    path: '/v3/cost/map-migrated/',
    title: 'AWS MAP',
    description:
      'Track Cost and Usage Report (CUR) based on the migrated resources to get migrated workloads’ spend and generate appropriate credits.',
    icon: <AWSMapIcon />,
  }),
  complianceLinks: [
    {
      path: soc2Url,
      title: 'SOC 2 Readiness Report',
      description: 'Check the SOC2 readiness and violations of your AWS resources and download an Excel report.',
      icon: <Soc2ReportIcon />,
    },
    {
      path: hipaaUrl,
      title: 'HIPAA Readiness Report',
      description: 'Use this dashboard to work towards HIPAA compliance on your AWS accounts and download a report.',
      icon: <HippaReportIcon />,
    },
    {
      path: cisUrl,
      title: 'CIS Readiness Report',
      description:
        'Live dashboard and downloadable report of security industry CIS 7.1 standard applied to your AWS resources.',
      icon: <CISReportIcon />,
    },
    {
      path: securityUrl,
      title: 'Security Report',
      description: 'The security pillar focuses on protecting information & systems. ',
      icon: <NavSecurityIcon />,
    },
  ],
  helpCenter: [
    {
      to: 'https://help.nops.io/',
      title: 'Go to Help Docs',
      icon: <HelpDocsIcon />,
    },
    {
      to: '',
      title: 'Start Chat with Support',
      icon: <SupportChatIcon />,
      customHandler: () => {
        window.Intercom('showNewMessage', 'Contact nOps Support: ');
      },
    },
  ],
};

export const rbacWithBCPlus = {
  dashboardLinks: [
    {
      path: '/v3/dashboards/',
      title: 'Dashboards',
      icon: <DashboardIcon />,
      isBCPlus: true,
    },
  ],
  showbackLinks: [
    {
      path: ROUTES_PATH.COST_ANALYSIS,
      title: 'Cost Analysis',
      description: 'Deeper analysis of cost trends over time by Showbacks.',
      icon: <CloudResourcesCostIcon />,
      isBCPlus: true,
    },
    {
      path: ROUTES_PATH.COST_ALLOCATIONS,
      title: 'Cost Allocation',
      description: 'Give Business Context to every dollar of AWS spend via Showbacks.',
      icon: <BillingAccountIcon />,
      isBCPlus: true,
    },
    {
      path: ROUTES_PATH.COST_ANALYSIS_REPORTS,
      title: 'Cost Analysis Reports',
      description: 'Manage your cost analysis reports and set notifications on the go.',
      icon: <ChargebackIcon />,
      svgClass: 'svg-transparent',
      isBCPlus: true,
    },
  ],
};

export const active_routes = {
  dashboardLinks: [
    '/v3/usage-report/dashboard/',
    '/v3/usage-report/dashboard_newsignup/',
    '/v3/dashboards/',
    '/v3/view-dashboards/',
  ],
  showbackLinks: [
    ROUTES_PATH.COST_ANALYSIS,
    ROUTES_PATH.COST_ALLOCATIONS,
    `${ROUTES_PATH.COST_ALLOCATIONS_DETAIL}:key/`,
    `${ROUTES_PATH.COST_ALLOCATIONS_RESOURCE}:key/`,
    `${ROUTES_PATH.COST_ALLOCATIONS_RESOURCE}:key/allocation-rules/`,
    `${ROUTES_PATH.COST_ALLOCATIONS_RESOURCE}:key/unallocated-resources/`,
    `${ROUTES_PATH.COST_ALLOCATIONS_RESOURCE}:key/allocation-rule-resources/:id/`,
    `${ROUTES_PATH.COST_ALLOCATIONS_RESOURCE}:key/showback-resources/`,
    '/v3/cost/chargebacks-center/',
    '/v3/cost/chargeback/:chargebackId',
    '/v3/cost/tag-explorer/',
    '/v3/cost/reserved/',
    ROUTES_PATH.COST_ANALYSIS_REPORTS,
    ROUTES_PATH.COST_ANALYSIS_TARGETS,
    '/v3/create-target/:id?',
    '/v3/update-target/:id',
    '/v3/graphql-ide/',
    '/v3/search/',
    '/v3/search/detail/:key/',
    ROUTES_PATH.RECOMMENDATIONS,
    '/v3/cost/recommendations/:type',
  ],
  nSwitchLinks: [
    '/v3/essentials/summary/',
    ROUTES_PATH.RIGHTSIZING_DASHBOARD,
    ROUTES_PATH.SCHEDULER_DASHBOARD,
    ROUTES_PATH.IDLE_RESOURCES_DASHBOARD,
    ROUTES_PATH.STORAGE_DASHBOARD,
    '/v3/essentials/recommendations/',
    '/v3/essentials/recommendations/:name',
    '/v3/essentials/scheduler-details/:type/:scheduler_id',
    '/v3/essentials/storage-details/:type/:scheduler_id',
    '/v3/essentials/rightsizing-details/:type/:scheduler_id',
    '/v3/essentials/idle-resources-details/:type/:scheduler_id',
  ],
  computeCopilotLinks: [
    '/v3/compute-copilot/summary/',
    '/v3/compute-copilot/:type/details/:id/',
    '/v3/compute-copilot/databricks-dashboard/',
    '/v3/compute-copilot/visibility-dashboard/:type/:id/',

    ROUTES_PATH.CM_DASHBOARD,
    ROUTES_PATH.ASG_DASHBOARD,
    ROUTES_PATH.EKS_DASHBOARD,
    ROUTES_PATH_V2.EKS_DASHBOARD,
    ROUTES_PATH.CONTAINER_RIGHTSIZING,
  ],
  workloadLinks: [
    ROUTES_PATH.RULE_DETAIL_PATHS,
    '/v3/workload/',
    '/v3/workload-v2/',
    '/v3/workload-v2/:workloadId',
    '/v3/workload-v2/:id/:name/resource-detail/:type',
    '/v3/workload-assessment-v2/:workload_id/:workload_assessment_id/:name/:lense',
    '/v3/workload-v2/detail/wafr-report',
    '/v3/workload-v2/detail/ftr-report',
    '/v3/workload-v2/detail/hipaa-report',
    '/v3/workload-v2/detail/soc2-report',
    '/v3/workload-v2/detail/cis-report',
    '/v3/war/',
    '/v3/cost/map-migrated/',
    '/v3/cost/map-migrated/:id',
    '/v3/rules/',
    '/v3/rules/?tab=Change%20Management',
    '/v3/cost/unused/',
    '/v3/cost/underutilized-resources/',
    '/v3/rules-dashboard/:key',
    '/v3/security-dashboard/:key',
    '/v3/hipaa-dashboard/:key',
    '/v3/soc2-dashboard/:key',
    '/v3/cis-dashboard/:key',
    '/v3/dashboard/list/',
    '/v3/dashboard-creation/',
    '/v3/dashboard-creation/:key',
  ],
  complianceLinks: [soc2Url, hipaaUrl, cisUrl, securityUrl],
  helpCenter: ['https://help.nops.io/'],
};

const convertRouteToRegex = route => {
  // Convert dynamic segments like ":id" to a regex pattern
  const regexString = route
    .replace(/:[^/]+/g, '([^/]+)') // Replace dynamic segments with regex groups
    .replace(/([/?])/g, '\\$1'); // Escape special characters like "/" and "?"

  return new RegExp(`^${regexString}$`);
};

export const isPathnameInRoutes = (pathname = '', links = []) => {
  const cleanPathname = pathname.split('?')[0]; // Remove query parameters

  return links.some(route => convertRouteToRegex(route).test(cleanPathname));
};

const DOCUMENTS_LINK = {
  '/v3/dashboards/': 'https://help.nops.io/docs/products/business-contexts/dashboards/customizable-dashboards',
  '/v3/view-dashboards/': 'https://help.nops.io/docs/products/business-contexts/dashboards/customizable-dashboards',
  [ROUTES_PATH.COST_ANALYSIS]: 'https://help.nops.io/docs/products/business-contexts/cost-analysis/cost_analysis',
  [ROUTES_PATH.COST_ALLOCATIONS]: 'https://help.nops.io/docs/products/business-contexts/cost_allocation',
  [ROUTES_PATH.COST_ALLOCATIONS_DETAIL]: 'https://help.nops.io/docs/products/business-contexts/cost_allocation',
  [ROUTES_PATH.COST_ALLOCATIONS_RESOURCE]: 'https://help.nops.io/docs/products/business-contexts/cost_allocation',
  [ROUTES_PATH.COST_ANALYSIS_REPORTS]:
    'https://help.nops.io/docs/products/business-contexts/cost-analysis/bc-global-reports',
  [ROUTES_PATH.COST_ANALYSIS_TARGETS]: 'https://help.nops.io/docs/products/business-contexts/cost_analysis_target',
  '/v3/create-target/': 'https://help.nops.io/docs/products/business-contexts/cost_analysis_target',
  '/v3/update-target/': 'https://help.nops.io/docs/products/business-contexts/cost_analysis_target',
  '/v3/search/': 'https://help.nops.io/docs/products/wafr/reports/dsl-query-builder',
  '/v3/essentials/': 'https://help.nops.io/docs/products/essentials/introduction',
  [ROUTES_PATH.CM_DASHBOARD]: 'https://help.nops.io/docs/products/compute-copilot/copilot-cm-configure',
  [ROUTES_PATH.ASG_DASHBOARD]: 'https://help.nops.io/docs/products/compute-copilot/ASG/copilot-asg-onboarding',
  [ROUTES_PATH.EKS_DASHBOARD]: 'https://help.nops.io/docs/products/compute-copilot/EKS/copilot-eks-onboarding',
  '/v3/compute-copilot': 'https://help.nops.io/docs/products/compute-copilot/introduction',
  '/v3/cost/map-migrated/': 'https://help.nops.io/docs/solutions/MAP/introduction',
};

const TABS_LINK = {
  [SETTINGS_TAB.TEAM_MEMBERS]: 'https://help.nops.io/docs/admin-settings/settings/settings-adding-users-to-nops',
  [SETTINGS_TAB.NOTIFICATIONS_CENTER]: 'https://help.nops.io/docs/admin-settings/settings/settings-notification-center',
  [SETTINGS_TAB.POLICY]: 'https://help.nops.io/docs/getting-started/IAM/iam-policy-nops-free-platform',
  [SETTINGS_TAB.SSO]: 'https://help.nops.io/docs/getting-started/SSO/sso-integration',
  [SETTINGS_TAB.CHANGE_PASSWORD]: 'https://help.nops.io/docs/admin-settings/settings/settings-change-password',
  [SETTINGS_TAB.INTEGRATIONS]:
    'https://help.nops.io/docs/agents-integrations/BC+%20Integrations/container-cost-integration',
};

const SUB_TABS_LINK = {
  [USERS_TAB.POLICY]: 'https://help.nops.io/docs/products/business-contexts/bcp-rbac',
  [INTEGRATION_TAB_V2.ASG_DASHBOARD]:
    'https://help.nops.io/docs/agents-integrations/Compute-Copilot-Integrations/asg-integration',
  [INTEGRATION_TAB_V2.ESSENTIALS]:
    'https://help.nops.io/docs/agents-integrations/Essentials-Integrations/eventbridge-integration',
  [INTEGRATION_TAB_V2.NOTIFICATIONS]:
    'https://help.nops.io/docs/agents-integrations/Notifications-Integrations/pagerduty-integration',
};

export const getHelpLink = (pathname, search) => {
  const tab = getParamFromQueryString(search, 'tab');
  const subTab = getParamFromQueryString(search, 'subTab');

  if (subTab && SUB_TABS_LINK[subTab]) {
    return SUB_TABS_LINK[subTab];
  }

  if (tab && TABS_LINK[tab]) {
    return TABS_LINK[tab];
  }

  const matchedPath = Object.keys(DOCUMENTS_LINK).find(key => pathname.includes(key));

  if (matchedPath) {
    return DOCUMENTS_LINK[matchedPath];
  }

  return 'https://help.nops.io/';
};
